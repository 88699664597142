import { gql } from 'graphql-request'

export const CHECK_COUPON_CODE = gql`
  query CheckCouponCode($coupon: String!, $paymentMethod: PaymentMethodEnum) {
    checkCouponCode(coupon: $coupon, paymentMethod: $paymentMethod) {
      promotionCode {
        promotion {
          name
          description
          userTerm
        }
        coin {
          expireWithinMonthsByActivity
          expireWithinMonthsByPaid
          name
          imgPath
          colorCode
        }
        benefitValue
        type
        buyCoinConditionValue
        buyCoinCondition
      }
    }
  }
`
