import { gql } from 'graphql-request'

export const GET_USER_COIN_RECORDS_FREE_COINS = gql`
  query GetUserCoinRecordsFreeCoins(
    $limitPerPage: Int
    $page: Int
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: GetUserCoinRecordsFreeCoinsOrderBy
    $coinId: Float
  ) {
    getUserCoinRecordsFreeCoins(
      limitPerPage: $limitPerPage
      page: $page
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
      coinId: $coinId
    ) {
      data {
        id
        createdAt
        expiredAt
        amount
        remaining
        promotionName
      }
      total
    }
  }
`
