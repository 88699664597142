import { gql } from 'graphql-request'

export const GET_CONSENT = gql`
  query LatestConsents {
    latestConsents {
      id
      name
      content
    }
  }
`
