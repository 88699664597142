import { plainToInstance, instanceToPlain } from 'class-transformer'

import { CHECK_IS_FOLLOWED_PEN_NAME } from '@client/collections/User/schemas/checkIsFollowedPenName'
import { CHECK_IS_FOLLOWED_USER } from '@client/collections/User/schemas/checkIsFollowedUser'
import { GET_PROFILE } from '@client/collections/User/schemas/getProfile'
import { GET_PROFILE_BY_SLUG } from '@client/collections/User/schemas/getProfileBySlug'
import { GET_PROFILE_BY_ID } from '@client/collections/User/schemas/getProgfileById'
import { REGISTER } from '@client/collections/User/schemas/register'
import { REGISTER_WITH_EMAIL_AND_PASSWORD } from '@client/collections/User/schemas/registerWithEmailAndPassword'
import { SEARCH_USER } from '@client/collections/User/schemas/searchUser'
import { TOGGLE_FOLLOW_PEN_NAME } from '@client/collections/User/schemas/toggleFollowPenName'
import { TOGGLE_FOLLOW_USER } from '@client/collections/User/schemas/toggleFollowUser'
import { gqlApiInstance } from '@client/init'
import { FollowSuccessStatusEnum } from '@interfaces/FollowSuccessStatusEnum'
import { MemberType } from '@models/chat/MemberType'
import { RegisterFormType } from '@models/user/RegisterFormType'
import { RegisterResponseType } from '@models/user/RegisterResponseType'
import { UserProfileType } from '@models/user/UserProfileType'
import { UserType } from '@models/user/UserType'
import { FORGOT_PASSWORD } from '@client/collections/User/schemas/forgotPassword'
import { RESET_PASSWORD } from '@client/collections/User/schemas/resetPassword'
import { REGISTER_WITH_FIREBASE } from '@client/collections/User/schemas/registerWithFirebase'
import { SignInWithSocialNetworkServiceFormType } from '@interfaces/SignInWithSocialNetworkServiceFormType'
import { SignInWithSocialNetworkServiceType } from '@models/user/SignInWithSocialNetworkServiceType'

export function useUserAction() {
  async function getProfile(): Promise<UserType> {
    const res = await gqlApiInstance.request(GET_PROFILE)

    return plainToInstance(UserType, res.getProfile)
  }

  async function getProfileById(id: number): Promise<UserProfileType> {
    const res = await gqlApiInstance.request(GET_PROFILE_BY_ID, {
      id,
    })
    return plainToInstance(UserProfileType, res.getProfileById)
  }

  async function searchUser(
    searchText: string,
    limitPerPage: number,
    page: number
  ): Promise<MemberType[]> {
    const res = await gqlApiInstance.request(SEARCH_USER, {
      searchText,
      page,
      limitPerPage,
    })
    return plainToInstance(MemberType, <any[]>res.searchUser.data)
  }

  async function getProfileBySlug(slug: string): Promise<UserProfileType> {
    const res = await gqlApiInstance.request(GET_PROFILE_BY_SLUG, {
      slug,
    })
    return plainToInstance(UserProfileType, res.getProfileBySlug)
  }

  async function registerWithEmailAndPassword(
    form: RegisterFormType
  ): Promise<RegisterResponseType> {
    const res = await gqlApiInstance.request(REGISTER_WITH_EMAIL_AND_PASSWORD, {
      registerInput: instanceToPlain(form, { excludeExtraneousValues: true }),
    })
    return plainToInstance(
      RegisterResponseType,
      res.registerWithEmailAndPassword.user
    )
  }

  async function register(
    form: RegisterFormType
  ): Promise<RegisterResponseType> {
    const res = await gqlApiInstance.request(REGISTER, {
      registerInput: {
        email: form.email,
        username: form.username,
        phoneNumber: form.phoneNumber,
        displayName: form.displayName,
        birthDay: form.birthDay,
        gender: form.gender,
        mareadsEmailNotification: form.mareadsEmailNotification,
        isAcceptPolicy: form.isAcceptPolicy,
        firstLoginProvider: form.firstLoginProvider,
      },
    })
    return plainToInstance(RegisterResponseType, res.register)
  }

  async function checkIsFollowedPenName(penNameId: number): Promise<boolean> {
    const res = await gqlApiInstance.request(CHECK_IS_FOLLOWED_PEN_NAME, {
      penNameId,
    })

    return res.checkIsFollowedPenName
  }

  async function checkIsFollowedUser(userId: number): Promise<boolean> {
    const res = await gqlApiInstance.request(CHECK_IS_FOLLOWED_USER, {
      userId,
    })

    return res.checkIsFollowedUser
  }

  async function toggleFollowUser(
    userId: number
  ): Promise<FollowSuccessStatusEnum> {
    const {
      toggleFollowUser: { type },
    } = await gqlApiInstance.request(TOGGLE_FOLLOW_USER, {
      toggleFollowUserInput: {
        userId,
      },
    })

    return type
  }

  async function toggleFollowPenName(
    penNameId: number
  ): Promise<FollowSuccessStatusEnum> {
    const {
      toggleFollowPenName: { type },
    } = await gqlApiInstance.request(TOGGLE_FOLLOW_PEN_NAME, {
      toggleFollowPenNameInput: {
        penNameId,
      },
    })

    return type
  }

  async function forgotPassword(email: string): Promise<void> {
    await gqlApiInstance.request(FORGOT_PASSWORD, {
      email,
    })
  }

  async function resetPassword(
    resetPasswordToken: string,
    password: string
  ): Promise<void> {
    await gqlApiInstance.request(RESET_PASSWORD, {
      resetPwdInput: {
        resetPasswordToken,
        password,
      },
    })
  }

  async function signInWithSocialNetworkService(
    form: SignInWithSocialNetworkServiceFormType
  ): Promise<SignInWithSocialNetworkServiceType> {
    const registerWithFirebaseInput: SignInWithSocialNetworkServiceFormType = {
      firstLoginProvider: form.firstLoginProvider,
    }

    if (form.birthDay) {
      registerWithFirebaseInput.birthDay = form.birthDay
    }

    if (form.gender) {
      registerWithFirebaseInput.gender = form.gender.toUpperCase()
    }

    if (form.phoneNumber) {
      registerWithFirebaseInput.phoneNumber = form.phoneNumber
    }

    const { registerWithFirebase } = await gqlApiInstance.request(
      REGISTER_WITH_FIREBASE,
      {
        registerWithFirebaseInput,
      }
    )

    return plainToInstance(
      SignInWithSocialNetworkServiceType,
      registerWithFirebase
    )
  }

  return {
    getProfile,
    getProfileById,
    searchUser,
    getProfileBySlug,
    registerWithEmailAndPassword,
    register,
    checkIsFollowedPenName,
    checkIsFollowedUser,
    toggleFollowUser,
    toggleFollowPenName,
    forgotPassword,
    resetPassword,
    signInWithSocialNetworkService,
  }
}
