import { useContext, useEffect } from 'react'

import {
  useModal,
  ModalContext,
} from '@hooks/contexts/ModalContext/ModalContext'
import { useAuthentication } from '@hooks/useAuthentication'
import { useRouter } from 'next/router'

export function UserManagement() {
  const { isAuthenticated, isUserLoading, isLogin } = useAuthentication()
  const loadingModal = useModal({ modal: 'loading' }, true)
  const confirmModal = useModal({ modal: 'confirm' }, true)
  const loginModal = useModal({ modal: 'login' })
  const { currentModal, isOpen } = useContext(ModalContext)
  const { callbackHandleAccountExistFor3rdPartySignIn } = useAuthentication()
  const router = useRouter()

  useEffect(() => {
    callbackHandleAccountExistFor3rdPartySignIn({ confirmModal, loginModal })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])

  useEffect(() => {
    if (isLogin && isUserLoading) {
      loadingModal.show()
    } else if (currentModal === 'loading' && isOpen) {
      loadingModal.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoading, isAuthenticated, isLogin])

  return null
}
