import { Button } from '@components/Buttons'
import {
  PunishmentPeriodEnum,
  punishmentPeriodValue,
} from '@interfaces/PunishmentPeriodEnum'
import { formatDateDomini } from '@lib/utils'
import { BanModalProps } from './interface'
import { BanTime } from './components/BanTime'

export function BanModal({ punishment, isBan, closeModal }: BanModalProps) {
  return (
    <>
      {isBan && (
        <div className='fixed right-0 left-0 top-0 bottom-0 bg-secondary-2 bg-opacity-30 flex justify-center items-center z-[100]'>
          <div className='relative flex flex-col w-auto h-auto max-w-full max-h-[90%] overflow-auto rounded-[10px] scrollbar-none'>
            <div className='bg-white py-[40px] w-[580px] flex flex-col items-center mobile:w-[348px]'>
              <div className='flex flex-col items-center desktop-tablet:w-[580px]'>
                <h1 className='font-mitr text-center text-[24px] text-secondary font-medium max-w-[450px] mb-[10px]'>
                  บัญชีของคุณถูกระงับใช้งาน
                  {punishment.period === PunishmentPeriodEnum.FOREVER
                    ? punishmentPeriodValue[PunishmentPeriodEnum.FOREVER]
                    : `เป็นเวลา ${punishmentPeriodValue[punishment.period]}`}
                </h1>
                <div className='text-center max-w-[450px] space-y-[20px]'>
                  {punishment.period === PunishmentPeriodEnum.FOREVER ? (
                    <>
                      <div className='font-sarabun text-[14px] font-light whitespace-pre-wrap'>
                        เนื่องจาก {punishment.message}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className='font-sarabun text-[14px] font-light'>
                          ตั้งแต่วันที่
                          {` ${formatDateDomini(
                            punishment.startPunishedAt
                          )} - ${formatDateDomini(punishment.endPunishedAt)}`}
                        </p>
                        <div className='font-sarabun text-[14px] font-light whitespace-pre-wrap'>
                          เนื่องจาก {punishment.message}
                        </div>
                      </div>
                      <BanTime endPunishedAt={punishment.endPunishedAt} />
                    </>
                  )}
                  <div>
                    <p className='font-sarabun text-[14px] font-light'>
                      สามารถติดต่อทีมงาน MaReads ได้ที่
                    </p>
                    <p className='font-sarabun text-[14px] font-light'>
                      admin@mareads.com
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex font-mitr text-secondary-1 justify-center items-center text-[14px] mt-[20px]'>
                <Button
                  className='text-white bg-primary w-[116px]'
                  type='button'
                  onClick={closeModal}
                >
                  รับทราบ
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
