import {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  ReactNode,
  MutableRefObject,
} from 'react'
import { createPortal } from 'react-dom'

type HTMLElRef = MutableRefObject<HTMLElement>

export interface PortalProps {
  className?: string
  children: ReactNode
  position?: 'absolute' | 'fixed'
  zIndex?: number
}

export function usePortal() {
  const isServer = typeof window === 'undefined'
  const portalRef = useRef(
    isServer ? null : document.createElement('div')
  ) as HTMLElRef
  const targetRef = useRef() as HTMLElRef

  useEffect(() => {
    if (isServer) {
      return
    }

    portalRef.current = document.createElement('div')
    portalRef.current.style.cssText = `
      position: absolute;
      top: 0;
      left: 0;
      width: max-content;
      background: bg-transparent;
      z-index: 10;
    `
  }, [isServer])

  const elToMountTo = useMemo(() => {
    if (isServer || !document) {
      return null
    }

    return document.querySelector('body')
  }, [isServer])

  useEffect(() => {
    if (isServer) {
      return undefined
    }

    if (
      !(elToMountTo instanceof HTMLElement) ||
      !(portalRef.current instanceof HTMLElement)
    ) {
      return undefined
    }

    const node = portalRef.current
    elToMountTo.appendChild(portalRef.current)

    return () => {
      elToMountTo.removeChild(node)
    }
  }, [isServer, elToMountTo, portalRef])

  const Portal = useCallback(
    ({ children, position, zIndex = 10 }: PortalProps) => {
      if (!portalRef.current) {
        return null
      }

      if (position) {
        portalRef.current.style.position = position
      }

      if (Number.isInteger(zIndex)) {
        portalRef.current.style.zIndex = zIndex.toString()
      }

      return createPortal(children, portalRef.current)
    },
    [portalRef]
  )

  return {
    portalRef,
    targetRef,
    Portal,
  }
}
