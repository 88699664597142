import { gql } from 'graphql-request'

export const REGISTER_WITH_EMAIL_AND_PASSWORD = gql`
  mutation RegisterWithEmailAndPassword(
    $registerInput: RegisterWithEmailAndPasswordInput!
  ) {
    registerWithEmailAndPassword(registerInput: $registerInput) {
      user {
        id
        email
        displayName
      }
      token {
        idToken
      }
    }
  }
`
