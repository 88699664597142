import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react'
import cx from 'classnames'

import { ButtonProps } from './interface'

export const Button: ForwardRefExoticComponent<
  ButtonProps & RefAttributes<HTMLButtonElement>
> = forwardRef(
  (
    { children, className, icon, ...props }: ButtonProps,
    ref?: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        className={cx(
          'flex font-mitr items-center rounded-[40px] h-[40px] py-[10px] px-[20px] justify-center disabled:opacity-30',
          className
        )}
        ref={ref}
        {...props}
      >
        <div className='flex items-center'>
          {icon && <div className='mr-[5px]'>{icon}</div>}
          {children}
        </div>
      </button>
    )
  }
)
