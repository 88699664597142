import { gql } from 'graphql-request'

export const REGISTER_WITH_FIREBASE = gql`
  mutation RegisterWithFirebase(
    $registerWithFirebaseInput: RegisterWithFirebaseInput
  ) {
    registerWithFirebase(
      registerWithFirebaseInput: $registerWithFirebaseInput
    ) {
      id
      email
      displayName
    }
  }
`
