import { Expose } from 'class-transformer'

export class TagType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  usedCount?: number

  isNew?: boolean

  isDeleted?: boolean
}
