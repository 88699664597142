import { toast } from 'react-toastify'

import { ErrorIcon } from '@components/icons/ErrorIcon'
import { InfoIcon } from '@components/icons/InfoIcon'
import { SuccessIcon } from '@components/icons/SuccessIcon'
import { WarnIcon } from '@components/icons/WarnIcon'

export function useAlert() {
  function error(message: string, autoClose?: number | false) {
    toast.error(message || '', {
      icon: <ErrorIcon />,
      autoClose,
    })
  }

  function info(message: string, autoClose?: number | false) {
    toast.info(message || '', { icon: <InfoIcon />, autoClose })
  }

  function success(message: string, autoClose?: number | false) {
    toast.success(message || '', {
      icon: <SuccessIcon />,
      autoClose,
    })
  }

  function warning(message: string, autoClose?: number | false) {
    toast.warning(message || '', { icon: <WarnIcon />, autoClose })
  }

  return {
    error,
    info,
    success,
    warning,
  }
}
