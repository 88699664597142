import { CoinTypeEnum } from '@interfaces/CoinTypeEnum'
import { Expose } from 'class-transformer'

export class UserCoinType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  imgPath: string

  @Expose()
  type: CoinTypeEnum

  @Expose()
  editable: boolean

  @Expose()
  value: number

  @Expose()
  colorCode: string
}
