import { Expose, Type } from 'class-transformer'
import { SearchRecommendType } from './SearchRecommendType'

export class SearchRecommendationResponse {
  @Expose()
  @Type(() => SearchRecommendType)
  data: SearchRecommendType[]

  @Expose()
  total: number

  @Expose()
  page: number
}
