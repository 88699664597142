import { FontFamilyEnum } from '@interfaces/FontFamilyEnum'

export interface ThemeProviderPropsType {
  children: React.ReactNode
}

export interface ThemeContextType {
  theme: ThemeEnum
  fontSize: number
  lineHeight: number
  fontFamily: FontFamilyEnum
  isLightTheme: boolean
  isSepiaTheme: boolean
  isNightTheme: boolean
  isDarkTheme: boolean
  isNightOrDarkTheme: boolean
  isLightOrSepiaTheme: boolean
  updateTheme: (type: ThemeEnum, isSetLocal?: boolean) => void
  updateFontSize: (value: number) => void
  updateLineHeight: (value: number) => void
  updateFontFamily: (value: FontFamilyEnum) => void
  setInitialData: () => void
  setThemeFromLocalStorage: () => void
}

export enum ThemeEnum {
  LIGHT = 'light-mode',
  SEPIA = 'sepia-mode',
  NIGHT = 'night-mode',
  DARK = 'dark-mode',
}
