import { Expose, Transform } from 'class-transformer'

export class CategoryMenuType {
  @Expose({ name: 'id' })
  @Transform(({ value }) => String(value))
  value: string

  @Expose({ name: 'name' }) label: string

  @Expose() isManga: boolean

  @Expose() isNovel: boolean
}
