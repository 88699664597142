import { gql } from 'graphql-request'

export const CREATE_PAYMENT_CARD = gql`
  mutation CreatePaymentCard($createPaymentCardInput: CreatePaymentCardInput!) {
    createPaymentCard(createPaymentCardInput: $createPaymentCardInput) {
      redirectUrl
      status
    }
  }
`
