import { plainToInstance } from 'class-transformer'

import { CATEGORY } from '@client/collections/Category/schemas/category'
import { GET_CATEGORY_LIST } from '@client/collections/Category/schemas/getAllCategoryList'
import { GET_CATEGORIES } from '@client/collections/Category/schemas/getCategories'
import { GET_CATEGORY_OPTIONS } from '@client/collections/Category/schemas/getCategory'
import { GET_RECOMMENDATION_BOOKS } from '@client/collections/Category/schemas/getRecommendationBooks'
import { AllCategorySearchType } from '@features/allCategory/pages/AllCategoryPage/interface'
import { BookEnum } from '@interfaces/BookEnum'
import { MenuTypeEnum } from '@interfaces/MenuTypeEnum'
import { CategoryMenuType } from '@models/category/CategoryMenuType'
import { CategoryOptionType } from '@models/category/CategoryOptionType'
import { CategoryType } from '@models/category/CategoryType'
import { RecommendCatagoryType } from '@models/category/RecommendCatagoryType'
import { SearchRecommendationResponse } from '@models/recommend/SearchRecommendationResponse'
import { gqlApiInstance } from '@client/init'

export function useCategoryAction() {
  async function getCategoryOptions(
    bookType?: BookEnum[]
  ): Promise<CategoryOptionType[]> {
    const { categories } = await gqlApiInstance.request(GET_CATEGORY_OPTIONS, {
      bookType,
    })

    return plainToInstance(CategoryOptionType, categories as [])
  }

  async function getCategories(): Promise<CategoryMenuType[]> {
    const { categories } = await gqlApiInstance.request(GET_CATEGORIES)

    return plainToInstance(CategoryMenuType, categories as [])
  }

  async function category(id: number): Promise<CategoryType> {
    const res = await gqlApiInstance.request(CATEGORY, {
      categoryId: id,
    })
    return res.category
  }

  async function getRecommendationBooks({
    categoryId,
    bookId,
    bookType,
    isEbook,
    limitPerPage,
  }: {
    categoryId?: number
    bookId?: number
    bookType?: BookEnum
    isEbook?: boolean
    limitPerPage: number
  }): Promise<RecommendCatagoryType[]> {
    const res = await gqlApiInstance.request(GET_RECOMMENDATION_BOOKS, {
      category: categoryId,
      bookId,
      isEbook,
      bookType,
      limitPerPage,
    })

    return plainToInstance(
      RecommendCatagoryType,
      <any[]>res.getRecommendationBooks
    )
  }

  async function getCategoryList({
    allCategorySearchParam,
    page,
    perpage,
    bookType,
  }: {
    allCategorySearchParam: AllCategorySearchType
    page: number
    perpage: number
    bookType: MenuTypeEnum
  }): Promise<SearchRecommendationResponse> {
    const [key, val] = allCategorySearchParam.orderBy.split(':')

    const { bookByCategory } = await gqlApiInstance.request(GET_CATEGORY_LIST, {
      limitPerPage: perpage,
      page,
      bookType,
      categoryId: allCategorySearchParam.categoryId
        ? Number(allCategorySearchParam.categoryId)
        : undefined,
      primaryType: allCategorySearchParam.primaryType,
      orderBy: {
        [key]: val,
      },
      secondaryType: allCategorySearchParam.secondaryType,
    })

    return plainToInstance(SearchRecommendationResponse, bookByCategory)
  }

  return {
    getCategoryOptions,
    getCategories,
    category,
    getRecommendationBooks,
    getCategoryList,
  }
}
