import { createContext, useContext, useMemo } from 'react'
import { useQuery } from 'react-query'

import { FunctionComponentType } from '@interfaces/commons/FunctionComponent'
import { CategoryMenuType } from '@models/category/CategoryMenuType'
import { useCategoryAction } from './category/useCategoryAction'

interface CategoriesContextType {
  categories: CategoryMenuType[]
  novelCategories: CategoryMenuType[]
  mangaCategories: CategoryMenuType[]
  isLoading: boolean
}

const CategoriesContext = createContext<CategoriesContextType>({
  categories: [],
  novelCategories: [],
  mangaCategories: [],
  isLoading: false,
})

export function CategoriesProvider({ children }: FunctionComponentType) {
  const categoryClient = useCategoryAction()
  const { data: categories = [], isLoading } = useQuery('categories', () =>
    categoryClient.getCategories()
  )

  const novelCategories = useMemo(
    () => categories?.filter(row => row.isNovel),
    [categories]
  )
  const mangaCategories = useMemo(
    () => categories?.filter(row => row.isManga),
    [categories]
  )

  const value = {
    categories,
    novelCategories,
    mangaCategories,
    isLoading,
  }

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  )
}

export function useCategories() {
  return useContext(CategoriesContext)
}
