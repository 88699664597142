import { gql } from 'graphql-request'

export const GET_USER_COIN_RECORDS_NEAR_EXPIRATION = gql`
  query GetUserCoinRecordsNearExpiration {
    getUserCoinRecordsNearExpiration {
      coinId
      coinName
      expireInSevenDays
      expireInFiveDays
      expireInOneDays
    }
  }
`
