import { gql } from 'graphql-request'

export const SIGN_IN_DEVICE = gql`
  mutation SignInDevice($signInDeviceInput: SignInDeviceInput) {
    signInDevice(signInDeviceInput: $signInDeviceInput) {
      canSignIn
      devices {
        id
        displayName
        lastActiveAt
      }
    }
  }
`
