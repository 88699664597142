import React from 'react'

import { DrawerProps } from './interface'

const withDrawer = (Component: React.FC<any>) => {
  const Hoc = ({ isOpen, currentDrawer, ...props }: DrawerProps) => {
    const newProps = {
      isOpen,
      currentDrawer,
      ...props,
    }
    return <Component {...newProps} />
  }
  return Hoc
}

export { withDrawer }
