import {
  FACEBOOK_PIXEL_ADD_COIN,
  FACEBOOK_PIXEL_BUY_CHAPTER,
  FACEBOOK_PIXEL_BUY_CHAPTER_LIST,
  FACEBOOK_PIXEL_DONATE_CHARACTER,
  FACEBOOK_PIXEL_REGISTER,
} from '@configs/config'

export function pageView() {
  window.fbq('track', 'PageView')
}

export function fbqAddCoin(value: number) {
  if (FACEBOOK_PIXEL_ADD_COIN) {
    window.fbq('track', FACEBOOK_PIXEL_ADD_COIN, { currency: 'THB', value })
  }
}

export function fbqBuyChapter(value: number) {
  if (FACEBOOK_PIXEL_BUY_CHAPTER) {
    window.fbq('trackCustom', FACEBOOK_PIXEL_BUY_CHAPTER, {
      currency: 'THB',
      value,
    })
  }
}

export function fbqDonateCharacter(value: number) {
  if (FACEBOOK_PIXEL_DONATE_CHARACTER) {
    window.fbq('track', FACEBOOK_PIXEL_DONATE_CHARACTER, {
      currency: 'THB',
      value,
    })
  }
}

export function fbqRegisterComplete() {
  if (FACEBOOK_PIXEL_REGISTER) {
    window.fbq('track', FACEBOOK_PIXEL_REGISTER)
  }
}

export function fbqBuyChapterList(value: number) {
  if (FACEBOOK_PIXEL_BUY_CHAPTER_LIST) {
    window.fbq('trackCustom', FACEBOOK_PIXEL_BUY_CHAPTER_LIST, {
      currency: 'THB',
      value,
    })
  }
}
