import { Expose, Transform } from 'class-transformer'

import { OLD_S3_URL, NEW_S3_URL } from '@configs/config'

export class MemberType {
  @Expose()
  id: number

  @Expose()
  displayName: string

  @Expose()
  profilePageSlug: string

  @Expose()
  @Transform(({ value }) => {
    if (value) {
      return value.replace(OLD_S3_URL, NEW_S3_URL)
    }

    return value
  })
  @Expose()
  avatarImgPath: string

  @Expose()
  isAdmin: boolean

  @Expose()
  email?: string

  @Expose()
  isPermanentBan: boolean
}
