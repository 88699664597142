import cn from 'classnames'

export function Loading({
  className,
  size = 30,
}: {
  className?: string
  size?: number
}) {
  return (
    <div
      className={cn('my-[20px] flex justify-center items-center', className)}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      <div className='h-full w-full rounded-full border-4 border-primary border-b-transparent animate-spin' />
    </div>
  )
}
