import { UserFirstLoginProviderEnum } from './UserFirstLoginProviderEnum'

export enum ProviderDataEnum {
  FACEBOOK = 'facebook.com',
  GOOGLE = 'google.com',
  PASSWORD = 'password',
  APPLE = 'apple.com',
  LINE = 'line.me',
  NAIIN = 'naiin.com',
}

export const providerDataValue = {
  [ProviderDataEnum.PASSWORD]: UserFirstLoginProviderEnum.EMAIL,
  [ProviderDataEnum.FACEBOOK]: UserFirstLoginProviderEnum.FACEBOOK,
  [ProviderDataEnum.APPLE]: UserFirstLoginProviderEnum.APPLE,
  [ProviderDataEnum.GOOGLE]: UserFirstLoginProviderEnum.GOOGLE,
  [ProviderDataEnum.NAIIN]: UserFirstLoginProviderEnum.NAIIN,
  [ProviderDataEnum.LINE]: UserFirstLoginProviderEnum.LINE,
}
