import { Expose } from 'class-transformer'

import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'

export class ConsentType {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  content: string

  @Expose()
  type: ConsentTypeEnum
}
