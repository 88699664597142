import { useMutation } from 'react-query'

import { useAuthentication } from '@hooks/useAuthentication'
import { useConsentAction } from '@hooks/consent/useConsentAction'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'
import { PrivacyPolicy } from './PrivacyPolicy'

export function InvalidPDPAConsent({
  updateInvalidConsent,
}: {
  updateInvalidConsent: (value?: ConsentTypeEnum) => void
}) {
  const consentClient = useConsentAction()
  const { refetchMyProfile, signOut, refetchUserCoin } = useAuthentication()

  const { mutate: createUserConsent, isLoading } = useMutation(
    () => consentClient.createUserConsent(),
    {
      onSuccess: async () => {
        updateInvalidConsent(undefined)
        await refetchMyProfile()
        await refetchUserCoin()
      },
    }
  )

  return (
    <div className='w-[800px] pt-[40px] tablet:w-[740px] mobile:w-[348px] tablet-mobile:px-[20px] mobile:pt-[20px] mobile:pb-[15px] px-0 pb-[15px]'>
      <PrivacyPolicy
        disabled={isLoading}
        onOk={async () => {
          await createUserConsent()
        }}
        onCancel={async () => {
          await signOut()
          updateInvalidConsent(undefined)
        }}
      />
    </div>
  )
}
