import { initializeApp } from '@firebase/app'
import { firebaseConfig } from '@configs/firebase'

const firebase = initializeApp(firebaseConfig)

async function registerMessagingSW() {
  if ('serviceWorker' in navigator) {
    const params = new URLSearchParams(firebaseConfig).toString()
    const serviceWorker = await navigator.serviceWorker.register(
      `/firebase-messaging-sw.js?${params}`
    )
    return serviceWorker
  }

  return null
}

async function unregisterMessagingSW() {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    registrations.forEach(registration => {
      if (
        registration.active?.scriptURL?.includes('/firebase-messaging-sw.js')
      ) {
        registration.unregister()
      }
    })
  }
}

export { firebase, registerMessagingSW, unregisterMessagingSW }
