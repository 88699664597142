import { gql } from 'graphql-request'

export const CREATE_PAYMENT_QR_DOLFIN = gql`
  mutation CreatePaymentQRDolfin(
    $createPaymentDolfinInput: CreatePaymentDolfinInput!
  ) {
    createPaymentQRDolfin(createPaymentDolfinInput: $createPaymentDolfinInput) {
      transactionId
      qrData
    }
  }
`
