import { GraphQLClient } from '@client/GraphQLClient'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'
import { UserPunishmentType } from '@models/user/UserPunishmentType'

export class Client {
  constructor(private client: GraphQLClient) {}

  public async setupHeader(token?: string) {
    if (token) {
      this.accessToken = token
    } else {
      this.accessToken = null
      this.client.setHeaders({})
      this.setSigningIn(false)
    }
  }

  public setupConsent(func: (value?: ConsentTypeEnum) => void) {
    this.client.setupConsent(func)
  }

  public setupBanModal(
    func: ({
      isBan,
      punishment,
    }: {
      isBan: boolean
      punishment: UserPunishmentType
    }) => void
  ) {
    this.client.setupBanModal(func)
  }

  public setSigningIn(isSigningIn: boolean) {
    this.isSigningIn = isSigningIn
  }

  get accessToken() {
    if (typeof window !== 'undefined') return localStorage.getItem('token')

    return null
  }

  set accessToken(token) {
    if (typeof token === 'undefined' || token === null) {
      localStorage.removeItem('token')
      return
    }

    localStorage.setItem('token', token)
  }

  get isSigningIn() {
    return (
      typeof window !== 'undefined' && localStorage.getItem('signIn') === 'true'
    )
  }

  set isSigningIn(isSignedIn: boolean) {
    if (isSignedIn) {
      localStorage.setItem('signIn', 'true')
    } else {
      localStorage.removeItem('signIn')
    }
  }
}
