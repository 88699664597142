import { Expose } from 'class-transformer'

export class AddressType {
  @Expose() id: number

  @Expose() fullName: string

  @Expose({ name: 'address' }) description: string

  @Expose() postcode: string

  @Expose() province: string

  @Expose() district: string

  @Expose() subDistrict: string

  @Expose() phoneNumber: string

  @Expose() email: string

  @Expose() isDefault: boolean
}
