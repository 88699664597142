import React, { useState, useContext } from 'react'

import { Modal } from '@components/Modal'
import ChallengePopup, {
  ChallengeModalListProps,
} from '@components/Challenge/components/ChallengePopup'
import { PromotionChallengeActionEnum } from '@interfaces/PromotionChallengeActionEnum'
import { ModalContextType, ModalProviderPropsType } from './interface'

const ChallengeModalContext = React.createContext<ModalContextType>({
  isOpen: false,
  showModal: () => {},
  hideModal: () => {},
  setCurrentModal: () => {},
  currentModal: [],
  setCurrentModalProps: () => {},
})

const ChallengeModalProvider = ({ children }: ModalProviderPropsType) => {
  const [currentModal, setCurrentModal] = useState<
    PromotionChallengeActionEnum[]
  >([])
  const [currentModalProps, setCurrentModalProps] = useState<any>([])
  const [isOpen, setIsOpen] = useState(false)

  const showModal = () => {
    setIsOpen(true)
  }

  const hideModal = () => {
    const newCurrentModal = [...currentModal]
    const newCurrentModalProps = [...currentModalProps]
    newCurrentModal.splice(0, 1)
    newCurrentModalProps.splice(0, 1)
    setCurrentModal(newCurrentModal)
    setCurrentModalProps(newCurrentModalProps)
    if (newCurrentModal.length === 0) {
      setIsOpen(false)
    }
  }

  const handleOnClickClose = () => {
    hideModal()
  }

  return (
    <ChallengeModalContext.Provider
      value={{
        isOpen,
        showModal,
        hideModal,
        setCurrentModal,
        setCurrentModalProps,
        currentModal,
      }}
    >
      {children}
      {currentModal.length ? (
        <Modal isOpen={isOpen} disabledClose onClose={handleOnClickClose}>
          <ChallengePopup action={currentModal[0]!} {...currentModalProps[0]} />
        </Modal>
      ) : null}
    </ChallengeModalContext.Provider>
  )
}

const useChallengeModal = () => {
  const {
    isOpen,
    showModal,
    setCurrentModal,
    setCurrentModalProps,
    hideModal,
  } = useContext(ChallengeModalContext)

  const show = (
    modal: PromotionChallengeActionEnum,
    props: ChallengeModalListProps
  ) => {
    setCurrentModal((prev: PromotionChallengeActionEnum[]) => [...prev, modal])
    if (props) {
      setCurrentModalProps((prev: ChallengeModalListProps[]) => [
        ...prev,
        props,
      ])
    }
    showModal()
  }

  const hide = () => {
    hideModal()
  }

  return { isOpen, show, hide }
}

export { ChallengeModalProvider, ChallengeModalContext, useChallengeModal }
