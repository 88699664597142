import { plainToInstance } from 'class-transformer'

import { GET_CONSENT } from '@client/collections/Consent/schemas/getConsent'
import { GET_CONSENTS } from '@client/collections/Consent/schemas/getConsents'
import { UPDATE_WRITER_CONSENT } from '@client/collections/Consent/schemas/updateWriterConsent'
import { gqlApiInstance } from '@client/init'
import { ConsentType } from '@models/consent/ConsentType'
import { LATEST_PDPA_CONSENTS } from '@client/collections/Consent/schemas/latestPDPAConsents'
import { PDPAConsentType } from '@models/consent/PDPAConsentType'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'
import { CREATE_USER_CONSENT } from '@client/collections/Consent/schemas/createUserConsent'

export function useConsentAction() {
  async function getPDPAConsent(): Promise<PDPAConsentType> {
    const { latestPDPAConsents } = await gqlApiInstance.request(
      LATEST_PDPA_CONSENTS
    )

    return plainToInstance(PDPAConsentType, {
      termsOfUse:
        latestPDPAConsents.find(
          (row: ConsentType) => row.type === ConsentTypeEnum.termsOfUse
        ).content || '',
      privacyPolicy:
        latestPDPAConsents.find(
          (row: ConsentType) => row.type === ConsentTypeEnum.privacyPolicy
        ).content || '',
    })
  }

  async function getWriterConsents(): Promise<ConsentType[]> {
    const { consents } = await gqlApiInstance.request(GET_CONSENTS)

    return plainToInstance(ConsentType, consents as [])
  }

  async function getWriterConsent(): Promise<ConsentType> {
    const { latestConsents } = await gqlApiInstance.request(GET_CONSENT)

    return plainToInstance(ConsentType, latestConsents)
  }

  async function updateWriterConsent(consentId: number): Promise<void> {
    await gqlApiInstance.request(UPDATE_WRITER_CONSENT, {
      consentId,
    })
  }

  async function createUserConsent(): Promise<void> {
    await gqlApiInstance.request(CREATE_USER_CONSENT)
  }

  return {
    getPDPAConsent,
    getWriterConsents,
    getWriterConsent,
    updateWriterConsent,
    createUserConsent,
  }
}
