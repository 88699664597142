import { GraphQLClient, RequestDocument, Variables } from 'graphql-request'
import jwtDecode from 'jwt-decode'
import dynamic from 'next/dynamic'
import { getAuth } from '@firebase/auth'
import Router from 'next/router'
import { IconProps } from '@components/icons/interface'
import { UserPunishmentType } from '@models/user/UserPunishmentType'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'

const ErrorIcon = dynamic<IconProps>(() =>
  import('@components/icons/ErrorIcon').then(v => v.ErrorIcon)
)

class Client {
  private client: GraphQLClient

  private setInvalidConsent: (value?: ConsentTypeEnum) => void

  private setBanModal: ({
    isBan,
    punishment,
  }: {
    isBan: boolean
    punishment: UserPunishmentType
  }) => void

  constructor(apiUrl: string) {
    this.client = new GraphQLClient(apiUrl)
  }

  request<T = any, V = Variables>(
    document: RequestDocument,
    variables?: V,
    requestHeaders?: HeadersInit
  ): Promise<T> {
    return this.makeRequest<T, V>(document, variables, requestHeaders)
  }

  setHeaders(headers: HeadersInit) {
    this.client.setHeaders(headers)
  }

  setHeader(key: string, value: string) {
    this.client.setHeader(key, value)
  }

  setupConsent(func: (value?: ConsentTypeEnum) => void) {
    this.setInvalidConsent = func
  }

  setupBanModal(
    func: ({
      isBan,
      punishment,
    }: {
      isBan: boolean
      punishment: UserPunishmentType
    }) => void
  ) {
    this.setBanModal = func
  }

  private async makeRequest<T = any, V = Variables>(
    document: RequestDocument,
    variables?: V,
    requestHeaders?: HeadersInit
  ): Promise<T> {
    const token =
      typeof window !== 'undefined' ? localStorage.getItem('token') : null

    if (token) {
      const jwt: { exp: number } = jwtDecode(token)
      const currentDate = new Date()
      const expireDate = new Date((jwt.exp - 60) * 1000)

      if (expireDate <= currentDate) {
        if (getAuth().currentUser) {
          const newIdToken = await getAuth().currentUser?.getIdToken()
          if (newIdToken) {
            this.setHeader('authorization', `Bearer ${newIdToken}`)

            localStorage.setItem('token', newIdToken)
          }
        }
      } else {
        this.setHeader('authorization', `Bearer ${token}`)
      }
    }

    // const token = localStorage.getItem('token')

    // if (token) {
    //   this.setHeader('authorization', `Bearer ${token}`)
    // } else if (getAuth().currentUser) {
    //   const newIdToken = await getAuth().currentUser?.getIdToken()
    //   if (newIdToken) {
    //     this.setHeader('authorization', `Bearer ${newIdToken}`)
    //     const jwt: { exp: number } = jwtDecode(newIdToken)
    //     const expires = new Date((jwt.exp - 60) * 1000)
    //     localStorage.setItem('token', newIdToken, { expires })
    //   }
    // }

    // if (getAuth().currentUser) {
    //   console.log('hello')
    //   const newIdToken = await getAuth().currentUser?.getIdToken()
    //   if (newIdToken) {
    //     this.setHeader('authorization', `Bearer ${newIdToken}`)
    //     // const jwt: { exp: number } = jwtDecode(newIdToken)
    //     // const expires = new Date((jwt.exp - 60) * 1000)
    //     // Cookies.set('token', newIdToken, { expires })
    //   }
    // }

    return new Promise((resolve, reject) => {
      this.client
        .request(document, variables, requestHeaders)
        .then(resolve)
        .catch(async (error: any) => {
          const errorCode = error?.response?.errors?.[0]?.code
          if (error.response?.status === 503) {
            if (
              typeof window !== 'undefined' &&
              Router.pathname !== '/maintenance'
            ) {
              Router.replace('/maintenance')
            }
          } else if (
            error?.response?.errors[0]?.statusCode === 403 &&
            error?.response?.errors[0]?.type === 'Device not found.'
          ) {
            const { unregisterMessagingSW } = await import('@lib/firebase')
            const { auth } = await import('@hooks/useAuthentication')
            const { toast } = await import('react-toastify')

            await auth.signOut()
            await unregisterMessagingSW()
            toast.error('คุณโดนเชิญออกจากระบบโดยผู้ใช้ท่านอื่น', {
              icon: <ErrorIcon />,
            })
            return
          } else if (
            error?.response?.errors[0]?.statusCode === 403 &&
            errorCode === 'user/was-banned'
          ) {
            this.setBanModal({
              isBan: true,
              punishment: error?.response?.errors[0]?.extras,
            })
          } else if (
            error?.response?.errors[0]?.statusCode === 401 &&
            (errorCode === 'auth/invalid-consent-version' ||
              errorCode === 'auth/invalid-pdpa-consent-version')
          ) {
            this.setInvalidConsent(
              errorCode === 'auth/invalid-consent-version'
                ? ConsentTypeEnum.writerConsent
                : ConsentTypeEnum.privacyPolicy
            )
          }

          // eslint-disable-next-line consistent-return
          return reject(error)
        })
    })
  }
}

export { Client as GraphQLClient }
