import { Expose } from 'class-transformer'

export class CreateOrUpdatePaymentTrueMoneyType {
  @Expose() merchantName: string

  @Expose() amount: number

  @Expose() transactionCreatedAt: string

  @Expose() transactionId: string

  @Expose() otpReference: string

  @Expose() account: string

  @Expose() authorizationCode: string

  @Expose() coupon: string

  @Expose() linkAgreement: string
}
