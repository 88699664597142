import React, { useState, useContext } from 'react'

import { Drawer } from '@components/Drawer'
import { DrawerList } from './DrawerList'
import {
  HookDrawerParams,
  DrawerContextType,
  DrawerListType,
  DrawerProviderPropsType,
} from './interface'

const DrawerContext = React.createContext<DrawerContextType>({
  isOpen: false,
  showDrawer: () => {},
  hideDrawer: () => {},
  setCurrentDrawer: () => {},
  setCurrentDrawerProps: () => {},
})

const DrawerProvider = ({ children }: DrawerProviderPropsType) => {
  const [currentDrawer, setCurrentDrawer] = useState<
    DrawerListType | undefined
  >(undefined)
  const [currentDrawerProps, setCurrentDrawerProps] = useState<any>({})
  const [isOpen, setIsOpen] = useState(false)
  const DrawerChildren = currentDrawer ? DrawerList[currentDrawer] : null

  const showDrawer = () => {
    document.querySelector('html')?.classList.add('hide-scroll-drawer')
    document.querySelector('body')?.classList.add('hide-scroll-drawer')
    setIsOpen(true)
  }

  const hideDrawer = () => {
    document.querySelector('html')?.classList.remove('hide-scroll-drawer')
    document.querySelector('body')?.classList.remove('hide-scroll-drawer')

    setIsOpen(false)
    setTimeout(() => {
      setCurrentDrawer(undefined)
    }, 400)
  }

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        showDrawer,
        hideDrawer,
        setCurrentDrawer,
        setCurrentDrawerProps,
      }}
    >
      {children}
      <Drawer
        isOpen={isOpen}
        closeDrawer={hideDrawer}
        currentDrawer={currentDrawer}
      >
        {DrawerChildren ? <DrawerChildren {...currentDrawerProps} /> : null}
      </Drawer>
    </DrawerContext.Provider>
  )
}

const useDrawer = ({ drawer, drawerProps }: HookDrawerParams) => {
  const {
    isOpen,
    showDrawer,
    setCurrentDrawer,
    setCurrentDrawerProps,
    hideDrawer,
  } = useContext(DrawerContext)

  const show = (props?: Record<string, unknown>) => {
    setCurrentDrawer(drawer)
    setCurrentDrawerProps({ ...drawerProps, ...props })
    showDrawer()
  }

  const hide = () => {
    hideDrawer()
  }

  return { isOpen, show, hide }
}

export { DrawerProvider, DrawerContext, useDrawer }
