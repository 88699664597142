import { gql } from 'graphql-request'

export const REGISTER = gql`
  mutation Register($registerInput: RegisterInput!) {
    register(registerInput: $registerInput) {
      id
      email
      displayName
    }
  }
`
