import { forwardRef } from 'react'
import { DateTime } from 'luxon'

import { formatDateDomini } from '@lib/utils'
import { usePortal } from '@hooks/usePortal'
import { FirstLoginProviderEnum } from '@interfaces/FirstLoginProviderEnum'

export interface PolicyDocumentProps {
  title: string
  content: string
  submittedDate: string
  fullName?: string
  phoneNumber?: string
  address: string
  firstLoginProvider: FirstLoginProviderEnum
  email?: string
}

export const PolicyDocument = forwardRef<HTMLDivElement, PolicyDocumentProps>(
  (
    {
      title,
      content,
      submittedDate,
      fullName,
      address,
      phoneNumber,
      firstLoginProvider,
      email,
    }: PolicyDocumentProps,
    ref
  ) => {
    const { Portal } = usePortal()
    const submitDate = DateTime.fromISO(submittedDate).setLocale('th')

    return (
      <Portal>
        <div ref={ref} id='policy-document' className='hidden w-[535px]'>
          <h2 style={{ fontSize: 40, color: '#0c1135' }}>{title}</h2>
          {submittedDate && (
            <p
              style={{
                fontSize: 12,
                color: '#858baf',
              }}
            >
              Submitted Date : {formatDateDomini(submittedDate)}
            </p>
          )}
          <div style={{ fontSize: 14, color: '#0c1135' }}>
            <strong>ข้าพเจ้า</strong> {fullName}
          </div>
          <div style={{ fontSize: 14, color: '#0c1135' }}>
            <strong>ที่อยู่</strong> {address}
          </div>
          <div style={{ fontSize: 14, color: '#0c1135' }}>
            <strong>โทรศัพท์</strong> {phoneNumber}
          </div>
          <div style={{ fontSize: 14, color: '#0c1135' }}>
            <strong>ลงทะเบียนโดยใช้ข้อมูลยืนยันตัวตนจากแพลตฟอร์ม</strong>
            {` ${
              firstLoginProvider === FirstLoginProviderEnum.EMAIL
                ? email
                : firstLoginProvider
            }`}
          </div>
          <div style={{ fontSize: 14, color: '#0c1135' }}>
            <strong>
              ได้อ่านและตกลงตามข้อตกลงการให้บริการระบบวรรณกรรมอิเล็กทรอนิกส์ด้านล่างแล้ว
              จึงขอให้คำรับรองว่าจะปฏิบัติตามเงื่อนไขทุกข้อทุกประการ
            </strong>
          </div>
          <br />
          <div
            className='policy-content'
            style={{ fontSize: 14, color: '#0c1135' }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <br />
          <br />
          <div style={{ fontSize: 14, color: '#0c1135', textAlign: 'right' }}>
            ลงชื่อผู้รับบริการ {fullName}
          </div>
          <br />
          <div style={{ fontSize: 14, color: '#0c1135', textAlign: 'right' }}>
            {`วันที่ ${submitDate.toFormat(
              'dd LLLL yyyy'
            )} เวลา ${submitDate.toFormat('HH:mm')} น.`}
          </div>
        </div>
      </Portal>
    )
  }
)
