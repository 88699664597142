import React, { useContext } from 'react'
import { deleteDoc, doc, getFirestore } from '@firebase/firestore'

import { Button } from '@components/Buttons'
import { PromotionChallengeActionEnum } from '@interfaces/PromotionChallengeActionEnum'
import { promotionChallengeActionText } from '@lib/promotion/utils'
import { ChallengeModalContext } from '@hooks/contexts/ChallengeModalContext/ChallengeModalContext'
import { useAuthentication } from '@hooks/useAuthentication'

export interface ChallengeModalListProps {
  id: string
  benefitValue?: number
  benefitCoin?: string
}

export interface ChallengePopupProps {
  id: string
  action: PromotionChallengeActionEnum
  benefitValue?: number
  benefitCoin?: string
}

const challengeImageSrc = {
  [PromotionChallengeActionEnum.LOVE]: '/challengeLove.png',
  [PromotionChallengeActionEnum.COMMENT]: '/challengeComment.png',
  [PromotionChallengeActionEnum.SHARE]: '/challengeShare.png',
  [PromotionChallengeActionEnum.CREATE_BOOK]: '/challengeCreateBook.png',
  [PromotionChallengeActionEnum.DONATE]: '/challengeDonate.png',
  [PromotionChallengeActionEnum.FINISH_PROFILE]: '/challegeFinishProfile.png',
  [PromotionChallengeActionEnum.READ]: '/challengeRead.png',
  [PromotionChallengeActionEnum.SET_PRICE_CHAPTER]:
    '/challengeSetPriceChapter.png',
  [PromotionChallengeActionEnum.UNLOCK_CHAPTER]: '/challengeUnlockChapter.png',
}

function ChallengePopup({
  id,
  action,
  benefitValue,
  benefitCoin,
}: ChallengePopupProps) {
  const { hideModal } = useContext(ChallengeModalContext)
  const { user } = useAuthentication()

  async function deleteChallenge() {
    const db = getFirestore()
    hideModal()
    await deleteDoc(doc(db, 'challenge', `${user.id}`, 'challenges', `${id}`))
  }

  return (
    <div className='py-[40px] w-[400px] px-[30px] mobile:px-[20px] flex flex-col items-center mobile:w-[348px]'>
      <div className='mb-[11px] font-mitr text-2xl font-medium text-center'>
        คุณได้รับเหรียญฟรี
      </div>
      <div className='mb-[20px] mobile:mb-[10px]'>
        <img
          src={challengeImageSrc[action]}
          alt={`${action} Challenge`}
          width={112}
        />
      </div>
      <div className='text-center font-light text-sm max-w-[220px] mb-[30px]'>
        <p>{`ได้รับ ${benefitValue?.toLocaleString()} เหรียญ${benefitCoin}`}</p>
        <p>{`จากกิจกรรม ${promotionChallengeActionText(action)}`}</p>
      </div>
      <Button
        type='button'
        className='bg-primary text-white w-[102px] text-[14px] mobile:w-full'
        onClick={deleteChallenge}
      >
        ตกลง
      </Button>
    </div>
  )
}

export default ChallengePopup
