import { Expose, Transform, Type } from 'class-transformer'

import { CoinLogEnum } from '@interfaces/CoinLogEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { ActivityEnum } from '@interfaces/ActivityEnum'
import { PaymentMethodEnum } from '@interfaces/PaymentMethodEnum'

export class ReferenceType {
  @Expose() id: number

  @Expose() name: string

  @Expose({ name: 'characterDescription' }) description: string

  @Expose() coupon: string

  @Expose() promotionName: string
}

export class CoinHistoryType {
  @Expose() id: number

  @Expose({ name: 'createdAt' }) datetime: string

  @Expose() paidCoin: number

  @Expose() freeCoin: number

  @Expose()
  @Transform(({ obj }) => obj.transaction.amount || 0)
  amount: number

  @Expose() type: CoinLogEnum

  @Expose()
  @Transform(({ obj }) => obj.transaction.platform)
  platform: PlatformEnum

  @Expose() activity: ActivityEnum

  @Expose()
  @Transform(({ obj }) => obj.transaction.paymentMethod)
  paymentMethod: PaymentMethodEnum

  @Expose()
  @Transform(({ obj }) => obj.transaction.transactionId)
  transactionId: string

  @Expose()
  @Type(() => ReferenceType)
  reference?: ReferenceType

  @Expose()
  @Transform(({ obj }) =>
    obj.coin
      ? obj.coin.imgPath
      : obj.coinLogs?.length
      ? obj.coinLogs[0].coin.imgPath
      : ''
  )
  coinImgPath: string
}
