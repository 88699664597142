import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

interface BanTimeProps {
  endPunishedAt: string
}

export function BanTime({ endPunishedAt }: BanTimeProps) {
  const endTime = DateTime.fromISO(endPunishedAt)
  const [text, setText] = useState(cal())
  const [intervalInstance, setIntervalInstance] = useState<NodeJS.Timer>()

  function cal() {
    const { days, hours, minutes, seconds } = endTime.diffNow([
      'days',
      'hours',
      'minutes',
      'seconds',
    ])

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      if (intervalInstance) {
        clearInterval(intervalInstance)
      }
      return '0 วัน 0:0:0 ชั่วโมง'
    }

    let message = `${hours}:${minutes}:${seconds.toFixed(0)} ชั่วโมง`

    if (days) message = `${days} วัน ${message}`

    return message
  }

  useEffect(() => {
    const intervalFunc = setInterval(() => setText(cal()), 1000)

    setIntervalInstance(intervalFunc)

    return () => {
      clearInterval(intervalFunc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <p className='font-sarabun text-[14px] font-bold text-primary'>
        <span className='font-light text-secondary-1'>ปลดล็อคในอีก</span> {text}
      </p>
    </div>
  )
}
