import { gql } from 'graphql-request'

export const GET_PROFILE_BY_SLUG = gql`
  query GetProfileBySlug($slug: String!) {
    getProfileBySlug(slug: $slug) {
      id
      avatarImgPath
      bannerImgPath
      fullName
      email
      username
      displayName
      facebookLink
      twitterLink
      totalFollowing
      totalFollower
      totalBook
      totalBookshelf
      totalRead
      aboutMe
      isFollowed
      isAdmin
      allowSendMessage
      isPermanentBan
    }
  }
`
