import React, { useEffect } from 'react'

import { useAuthentication } from '@hooks/useAuthentication'
import { PunishmentPeriodEnum } from '@interfaces/PunishmentPeriodEnum'
import { BanModalAcceptProps, BanModalProps } from './interface'

const defaultPunishment = {
  period: PunishmentPeriodEnum.TWELVE_HOURS,
  startPunishedAt: '',
  endPunishedAt: '',
  message: '',
}

export function withBanModal(Component: React.FC<BanModalProps>) {
  function WithBanModal({
    banModalParams,
    setBanModalParams,
  }: BanModalAcceptProps) {
    const { signOut } = useAuthentication()

    async function closeModal() {
      await signOut()
      document.body.style.overflow = ''
      setBanModalParams(prev => ({
        ...prev,
        isBan: false,
      }))
    }

    useEffect(() => {
      if (banModalParams.isBan) {
        document.body.style.overflow = 'hidden'
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [banModalParams])

    const componentProps = {
      punishment: banModalParams.punishment || defaultPunishment,
      isBan: banModalParams.isBan,
      closeModal,
    }

    return <Component {...componentProps} />
  }

  return WithBanModal
}
