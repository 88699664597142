import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'
import { InvalidPDPAConsent } from './InvalidPDPAConsent'
import { InvalidWriterConsent } from './InvalidWriterConsent'

export function InvalidConsent({
  invalidConsent,
  updateInvalidConsent,
  isMaintenance = false,
}: {
  invalidConsent?: ConsentTypeEnum
  updateInvalidConsent: (value?: ConsentTypeEnum) => void
  isMaintenance?: boolean
}) {
  if (!invalidConsent || isMaintenance) return null

  return (
    <div className='fixed right-0 left-0 top-0 bottom-0 bg-secondary-2 bg-opacity-30 flex justify-center items-center z-[200]'>
      <div className='relative flex flex-col w-auto h-auto max-w-full max-h-[90%] overflow-auto rounded-[10px] scrollbar-none bg-white'>
        {invalidConsent === ConsentTypeEnum.writerConsent ? (
          <InvalidWriterConsent updateInvalidConsent={updateInvalidConsent} />
        ) : (
          <InvalidPDPAConsent updateInvalidConsent={updateInvalidConsent} />
        )}
      </div>
    </div>
  )
}
