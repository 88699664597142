import { gql } from 'graphql-request'

export const GET_CONSENTS = gql`
  query Consents {
    consents {
      id
      name
      content
      type
    }
  }
`
