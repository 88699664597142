import { useEffect } from 'react'

import { Button } from '@components/Buttons'
import { CircleCheck2Icon } from '@components/icons/CircleCheck2Icon'
import { timeSince } from '@lib/utils'
import { auth } from '@hooks/useAuthentication'
import { LoadingModal } from '@components/LoadingModalV2/LoadingModal'
import { LimitAuthenticationModalProps, DeviceCardProps } from './interface'

function TriggleSignOut() {
  useEffect(() => {
    async function handleBeforeUnload() {
      await auth.signOut()
    }
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return null
}

function DeviceCard({ name, datetime, removeDevice }: DeviceCardProps) {
  return (
    <div className='grid grid-cols-[40px_146px_124px] gap-x-[20px] items-center p-[20px] rounded-[8px] shadow border border-gray-4 tablet-mobile:grid-cols-[40px_1fr] tablet-mobile:gap-y-[10px]'>
      <div className='text-primary w-[40px] h-[40px]'>
        <CircleCheck2Icon width='100%' height='100%' />
      </div>
      <div className='text-left overflow-hidden'>
        <div className='font-mitr font-medium line-clamp-1 mb-[5px]'>
          {name}
        </div>
        <div className='text-[14px] font-light text-secondary-1'>
          {timeSince(datetime)}
        </div>
      </div>

      <Button
        className='border border-secondary text-[14px] tablet-mobile:col-start-2 tablet-mobile:col-span-1 tablet-mobile:w-[124px]'
        type='button'
        onClick={removeDevice}
      >
        ออกจากระบบ
      </Button>
    </div>
  )
}

export function LimitAuthenticationModal({
  devices,
  isShowLimitDevice,
  isLoading,
  closeModal,
  openLoginModal,
  removeDevice,
  showLimitDevice,
}: LimitAuthenticationModalProps) {
  return (
    <div className='fixed right-0 left-0 top-0 bottom-0 bg-secondary-2 bg-opacity-30 flex justify-center items-center z-[100]'>
      <div className='relative flex flex-col w-auto h-auto max-w-full max-h-[90%] overflow-auto rounded-[10px] scrollbar-none'>
        <TriggleSignOut />
        {isShowLimitDevice ? (
          <div className='bg-white flex flex-col items-center text-secondary text-center w-[860px] px-[30px] py-[40px] tablet:w-[700px] mobile:w-[348px] mobile:px-[20px]'>
            <div className='font-mitr text-[24px] font-medium mb-[10px]'>
              การเข้าใช้ผ่านอุปกรณ์เกินจำนวนที่กำหนด
            </div>
            <div className='text-[16px] font-light mb-[30px] w-[342px] mobile:w-[310px]'>
              คุณสามารถ Login เข้าใช้งานได้ไม่เกิน 5 อุปกรณ์
              กรุณาเข้าใช้งานแทนที่อุปกรณ์ด้านล่างนี้ เพื่อเข้าใช้งาน
            </div>
            <div className='grid grid-cols-2 gap-[20px] mobile:grid-cols-1'>
              {devices.map(row => (
                <DeviceCard
                  key={row.id}
                  name={row.displayName}
                  datetime={row.lastActiveAt}
                  removeDevice={() => removeDevice(row.id)}
                />
              ))}
            </div>
            <div className='flex justify-center space-x-[20px] font-mitr mt-[30px]'>
              <button
                className='text-[12px] font-medium'
                type='button'
                onClick={closeModal}
              >
                ยกเลิก
              </button>
              <button
                className='text-[12px] text-primary underline font-medium'
                type='button'
                onClick={openLoginModal}
              >
                เข้าสู่ระบบด้วยผู้ใช้งานอื่น
              </button>
            </div>
          </div>
        ) : (
          <div className='bg-white py-[40px] w-[400px] flex flex-col items-center mobile:w-[348px]'>
            <div className='flex flex-col items-center desktop-tablet:w-[400px] mb-[20px]'>
              <h1 className='font-mitr text-center text-[24px] text-secondary font-medium max-w-[260px]'>
                การเข้าใช้ผ่านอุปกรณ์เกิน จำนวนที่กำหนด
              </h1>
              <p className='font-sarabun text-[14px] font-light'>
                คุณต้องการออกจากระบบอุปกรณ์อื่นหรือไม่
              </p>
            </div>
            <div className='flex font-mitr text-secondary-1 justify-center items-center text-[14px]'>
              <Button
                data-test='cancel'
                className='font-medium border border-secondary-1'
                type='button'
                onClick={closeModal}
              >
                ยกเลิก
              </Button>
              <Button
                data-test='confirm'
                className='text-white bg-primary min-w-[109px] whitespace-nowrap ml-[20px]'
                type='button'
                onClick={showLimitDevice}
              >
                ตกลง
              </Button>
            </div>
          </div>
        )}
      </div>
      <LoadingModal isOpen={isLoading} />
    </div>
  )
}
