import { gql } from 'graphql-request'

export const LATEST_PDPA_CONSENTS = gql`
  query LatestPDPAConsents {
    latestPDPAConsents {
      id
      name
      content
      type
    }
  }
`
