import { gql } from 'graphql-request'

export const TOGGLE_FOLLOW_PEN_NAME = gql`
  mutation ToggleFollowPenName(
    $toggleFollowPenNameInput: ToggleFollowPenNameInput!
  ) {
    toggleFollowPenName(toggleFollowPenNameInput: $toggleFollowPenNameInput) {
      type
    }
  }
`
