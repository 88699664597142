import { Expose } from 'class-transformer'

export class CoinNearExpirationType {
  @Expose()
  coinId: number

  @Expose()
  coinName: string

  @Expose()
  expireInSevenDays: number

  @Expose()
  expireInFiveDays: number

  @Expose()
  expireInOneDays: number
}
