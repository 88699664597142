/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAuthentication, auth } from '@hooks/useAuthentication'
import { useDeviceAction } from '@hooks/device/useDeviceAction'
import { useAlert } from '@hooks/useAlert'
import { useDebounce } from '@hooks/useDebounce'
import { LimitAuthenticationModalProps } from './interface'

export function withLimitAuthenticationModal(
  Component: React.FC<LimitAuthenticationModalProps>
) {
  function WithLimitAuthenticationModal() {
    const { debounce } = useDebounce()
    const [isShowLimitDevice, setIsShowLimitDevice] = useState(false)
    const { devices, token, signInDevice } = useAuthentication()
    const deviceClient = useDeviceAction()
    const alert = useAlert()
    const loginModal = useModal({ modal: 'login' })
    const [isLoading, setLoading] = useState(false)

    function showLimitDevice() {
      setIsShowLimitDevice(true)
    }

    async function closeModal() {
      await auth.signOut()
    }

    async function openLoginModal() {
      await auth.signOut()
      loginModal.show()
    }

    async function removeDevice(id: number) {
      debounce(async () => {
        try {
          setLoading(true)
          if (token) {
            await deviceClient.removeDevice(token, id)
            await signInDevice()
          }
        } catch (error) {
          alert.error(
            'ไม่สามารถออกจากระบบบนอุปกรณ์ที่เลือกได้ กรุณาลองใหม่อีกครั้ง'
          )
        } finally {
          setLoading(false)
        }
      }, 400)
    }

    const componentProps = {
      devices,
      isShowLimitDevice,
      isLoading,
      closeModal,
      openLoginModal,
      removeDevice,
      showLimitDevice,
    }

    return <Component {...componentProps} />
  }

  return WithLimitAuthenticationModal
}
