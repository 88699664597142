import { Expose, Type } from 'class-transformer'
import { PromotionCodeTypeEnum } from '@interfaces/PromotionCodeTypeEnum'

export class PromotionType {
  @Expose() name: string

  @Expose() description: string

  @Expose() userTerm: string
}

export class CoinType {
  @Expose() expireWithinMonthsByActivity: number

  @Expose() expireWithinMonthsByPaid: number

  @Expose() name: string

  @Expose() imgPath: string

  @Expose() colorCode: string
}

export class CheckRedeemCodeType {
  @Expose()
  @Type(() => PromotionType)
  promotion: PromotionType

  @Expose()
  @Type(() => CoinType)
  coin: CoinType

  @Expose() benefitValue: number

  @Expose() type: PromotionCodeTypeEnum
}
