import React, { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import { useChallengeModal } from '@hooks/contexts/ChallengeModalContext/ChallengeModalContext'
import { useAuthentication } from '@hooks/useAuthentication'

function ChallengeSnapshot() {
  const challengeModal = useChallengeModal()
  const { user } = useAuthentication()
  const unsubscribe = useRef<any>(null)
  const queryClient = useQueryClient()

  async function initialChallengeSnapshot() {
    const { collection, getFirestore, onSnapshot, query } = await import(
      '@firebase/firestore'
    )
    const db = getFirestore()

    const queryChallenges = query(
      collection(db, 'challenge', `${user.id}`, 'challenges')
    )

    unsubscribe.current = onSnapshot(queryChallenges, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          queryClient.invalidateQueries('userCoin')
          challengeModal.show(change.doc.get('action'), {
            id: change.doc.id,
            benefitValue: change.doc.get('benefitValue'),
            benefitCoin: change.doc.get('benefitCoin'),
          })
        }
      })
    })
  }

  useEffect(() => {
    initialChallengeSnapshot()
    return () => {
      if (unsubscribe.current) {
        unsubscribe.current()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id])

  return <></>
}

export default ChallengeSnapshot
