import { Expose, Transform, Type } from 'class-transformer'

import { GenderEnum } from '@interfaces/GenderEnum'
import { AgeVerifyEnum } from '@interfaces/AgeVerifyEnum'
import { WriterType } from '@models/writer/WriterType'
import { WriterRequestType } from '@models/writer/WriterRequestType'
import { AddressType } from '@models/address/AddressType'
import { FirstLoginProviderEnum } from '@interfaces/FirstLoginProviderEnum'

export class UserType {
  @Expose() id: number

  @Expose() avatarImgPath: string

  @Expose() bannerImgPath: string

  @Expose() idNumber: string

  @Expose()
  @Transform(({ value }) => value || '')
  fullName: string

  @Expose() email: string

  @Expose() phoneNumber: string

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : null))
  birthDay: Date | null

  @Expose()
  @Transform(({ value }) =>
    typeof value === 'string' ? value.toUpperCase() : GenderEnum.OTHER
  )
  gender: GenderEnum

  @Expose() isAcceptPolicy: boolean

  @Expose() username: string

  @Expose() displayName: string

  @Expose() facebookLink: string

  @Expose() twitterLink: string

  @Expose() profilePageSlug: string

  @Expose()
  @Transform(({ value }) => value || '')
  aboutMe: string

  @Expose() allowReadLikeHistory: boolean

  @Expose() allowSendMessage: boolean

  @Expose() allowShowCoinUsage: boolean

  @Expose()
  totalFollowing: number

  @Expose()
  totalFollower: number

  @Expose() totalBook: number

  @Expose() totalBookshelf: number

  @Expose() totalRead: number

  @Expose() ageVerify: AgeVerifyEnum | null

  @Expose() idCardImgPath: string

  @Expose() newBookNotification: boolean

  @Expose() newChapterNotification: boolean

  @Expose() contentUpdatedNotification: boolean

  @Expose() commentRepliedNotification: boolean

  @Expose() bookEndedNotification: boolean

  @Expose() pushNotification: boolean

  @Expose() mareadsEmailNotification: boolean

  @Expose() coinPurchasedEmailNotification: boolean

  @Expose() coinUsedEmailNotification: boolean

  @Expose()
  @Type(() => AddressType)
  @Transform(({ value }) => value || [])
  addresses: AddressType[]

  @Expose()
  @Type(() => WriterType)
  writer: WriterType | null

  @Expose()
  @Type(() => WriterRequestType)
  writerRequest: WriterRequestType | null

  @Expose()
  subscribedWriterNotification: boolean

  @Expose()
  commentedWriterNotification: boolean

  @Expose()
  likedWriterNotification: boolean

  @Expose()
  supportedWriterNotification: boolean

  @Expose()
  pushWriterNotification: boolean

  @Expose()
  mareadsEmailWriterNotification: boolean

  @Expose()
  salesSummaryWriterNotification: boolean

  @Expose()
  writingDisabled: boolean

  @Expose()
  isBan: boolean

  @Expose()
  isAdmin: boolean

  @Expose()
  isPermanentBan: boolean

  @Expose()
  ageVerifyRejectedReason: string

  @Expose() firstLoginProvider: FirstLoginProviderEnum

  @Expose() usernameEditable: boolean

  @Expose() emailEditable: boolean

  @Expose({ name: 'webHomeBannerEnable' }) homeBannerEnable: boolean

  @Expose({ name: 'webCoinBannerEnable' }) coinBannerEnable: boolean
}
