import { useState } from 'react'
import { useQuery } from 'react-query'

import { Button } from '@components/Buttons'
import { Loading } from '@components/Loading'
import { useConsentAction } from '@hooks/consent/useConsentAction'
import { PrivacyPolicyProps } from './interface'

export function PrivacyPolicy({
  disabled,
  onOk,
  onCancel,
}: PrivacyPolicyProps) {
  const consentClient = useConsentAction()
  const [disabledOkButton, setDisabledOkButton] = useState(true)
  const { data, isLoading } = useQuery('pdpa', () =>
    consentClient.getPDPAConsent()
  )

  if (isLoading || !data) return <Loading className='mx-auto' />

  return (
    <>
      <h2 className='font-mitr text-[24px] text-secondary font-medium px-[30px] mobile:px-[20px]'>
        เงื่อนไขการใช้บริการและนโยบายส่วนบุคคลแพลตฟอร์ม MaReads
      </h2>
      <div
        className='h-[380px] overflow-y-auto scrollbar-thumb-secondary-1 relative mt-[20px]'
        onScroll={(e: any) => {
          const scrollHeight = e.target.scrollHeight - 10
          const scrollTop = e.target.scrollTop + e.target.offsetHeight
          if (scrollHeight <= scrollTop) {
            setDisabledOkButton(false)
          }
        }}
      >
        <div
          className='pdpa-consent text-[14px] text-secondary font-light mt-[10px] px-[30px] mobile:px-[20px]'
          dangerouslySetInnerHTML={{
            __html: `${data.termsOfUse}<br />${data.privacyPolicy}<br /><p style='text-indent: 50px'>ผู้ใช้งานจะได้รับข้อมูลนี้ผ่านทางอีเมลที่ท่านกรอกลงระบบ MaReads</p>`,
          }}
        />
        <div className='w-full h-[80px] sticky bottom-0 bg-gradient-to-b from-[#ffffff00] to-[#393c4e1a]' />
      </div>
      <div className='flex justify-end space-x-[10px] mt-[15px] px-[30px] mobile:px-[20px] text-[14px] font-medium'>
        <Button
          type='button'
          className='font-mitr text-secondary-1 border border-secondary-1'
          onClick={onCancel}
        >
          <span>ยกเลิก</span>
        </Button>
        <Button
          className='bg-primary font-mitr text-white'
          type='button'
          onClick={onOk}
          disabled={disabled || disabledOkButton}
        >
          <span>ยอมรับ</span>
        </Button>
      </div>
    </>
  )
}
