import { Expose, Type } from 'class-transformer'
import { CoinHistoryType } from './CoinHistoryType'

export class UserCoinLogsResponse {
  @Expose()
  @Type(() => CoinHistoryType)
  data: CoinHistoryType[]

  @Expose() total: number

  @Expose() page: number
}
