import { Expose, Transform } from 'class-transformer'

export class UserProfileType {
  @Expose() id: number

  @Expose() avatarImgPath: string

  @Expose() bannerImgPath: string

  @Expose()
  @Transform(({ value }) => value || '')
  fullName: string

  @Expose() username: string

  @Expose() displayName: string

  @Expose() email: string

  @Expose() facebookLink: string

  @Expose() twitterLink: string

  @Expose()
  totalFollowing: number

  @Expose()
  totalFollower: number

  @Expose() totalBook: number

  @Expose() totalBookshelf: number

  @Expose() totalRead: number

  @Expose()
  @Transform(({ value }) => value || '')
  aboutMe: string

  @Expose() isFollowed: boolean

  @Expose() isAdmin: boolean

  @Expose() allowSendMessage: boolean

  @Expose() isPermanentBan: boolean
}
