import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class FreeCoinType {
  @Expose()
  id: number

  @Expose()
  createdAt: string

  @Expose()
  expiredAt: string

  @Expose()
  amount: number

  @Expose()
  remaining: number

  @Expose()
  promotionName: string

  @Expose()
  @Transform(({ obj }) => {
    const currentTime = DateTime.now().toMillis()
    const expireDate = DateTime.fromISO(obj.expiredAt).toMillis()

    return expireDate - currentTime <= 0
  })
  isExpire: boolean
}
