import React, { useState, useContext } from 'react'

import { Modal } from '@components/Modal'
import {
  HookModalParams,
  ModalContextType,
  ModalListType,
  ModalProviderPropsType,
} from './interface'
import { ModalList } from './ModalList'

const ModalContext = React.createContext<ModalContextType>({
  isOpen: false,
  showModal: () => {},
  hideModal: () => {},
  setCurrentModal: () => {},
  currentModal: undefined,
  setCurrentModalProps: () => {},
  disabledCloseButton: (disabled = false) => {},
  setOnClickClose: () => {},
})

const ModalProvider = ({ children }: ModalProviderPropsType) => {
  const [currentModal, setCurrentModal] = useState<ModalListType | undefined>(
    undefined
  )
  const [currentModalProps, setCurrentModalProps] = useState<any>({})
  const [isOpen, setIsOpen] = useState(false)
  const [disabledClose, setDisabledClose] = useState(false)
  const [onClickClose, setOnClickClose] = useState<(() => void) | undefined>()
  const ModalChildren = ModalList[currentModal!]

  const showModal = () => {
    // document.querySelector('html')?.classList.add('hide-scroll-modal')
    document.querySelector('body')?.classList.add('hide-scroll-modal')
    setIsOpen(true)
  }
  const hideModal = () => {
    // document.querySelector('html')?.classList.remove('hide-scroll-modal')
    document.querySelector('body')?.classList.remove('hide-scroll-modal')
    setIsOpen(false)
  }

  const handleOnClickClose = () => {
    if (onClickClose) {
      onClickClose()
    }
    hideModal()
  }

  const disabledCloseButton = (disabled = false) => {
    setDisabledClose(disabled)
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        showModal,
        hideModal,
        setCurrentModal,
        setCurrentModalProps,
        disabledCloseButton,
        setOnClickClose,
        currentModal,
      }}
    >
      {children}
      {currentModal && (
        <Modal
          isOpen={isOpen}
          onClose={handleOnClickClose}
          disabledClose={disabledClose}
          disableBackground={['loading'].includes(currentModal)}
        >
          <ModalChildren {...currentModalProps} />
        </Modal>
      )}
    </ModalContext.Provider>
  )
}

const useModal = (
  { modal, modalProps, handleOnClickClose }: HookModalParams,
  hideCloseButton?: boolean
) => {
  const {
    isOpen,
    showModal,
    setCurrentModal,
    setCurrentModalProps,
    hideModal,
    disabledCloseButton,
    setOnClickClose,
  } = useContext(ModalContext)

  const show = (props?: Record<string, unknown>) => {
    disabledCloseButton(hideCloseButton)
    setCurrentModal(modal)
    setCurrentModalProps({ ...modalProps, ...props })
    setOnClickClose(() => handleOnClickClose)
    showModal()
  }

  const hide = () => {
    hideModal()
  }

  return { isOpen, show, hide }
}

export { ModalProvider, ModalContext, useModal }
