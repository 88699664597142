import { gql } from 'graphql-request'

export const GET_CATEGORY_LIST = gql`
  query BookByCategory(
    $limitPerPage: Int = 10
    $page: Int = 1
    $bookType: BookType!
    $categoryId: Int
    $primaryType: categoryFilterBy!
    $orderBy: CategoryOrderBy
    $secondaryType: RecommendTypeSearch
  ) {
    bookByCategory(
      limitPerPage: $limitPerPage
      page: $page
      bookType: $bookType
      categoryId: $categoryId
      primaryType: $primaryType
      orderBy: $orderBy
      secondaryType: $secondaryType
    ) {
      data {
        id
        writer
        coverImgPath
        cover
        title
        publishedChapterCount
        viewCount
        likeCount
        isLiked
        commentCount
        isEnded
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            id
            displayName
            profilePageSlug
            avatarImgPath
            writer {
              affiliation
              status
            }
          }
        }
        tags {
          id
          name
        }
        bookType
        isTranslated
        isEpub
        isPdf
        writingType
        publishedAt
      }
      total
      page
    }
  }
`
