import { gql } from 'graphql-request'

export const CONFIRM_PAYMENT_TRUE_MONEY = gql`
  mutation ConfirmPaymentTrueMoney(
    $confirmPaymentTrueMoneyInput: ConfirmPaymentTrueMoneyInput!
  ) {
    confirmPaymentTrueMoney(
      confirmPaymentTrueMoneyInput: $confirmPaymentTrueMoneyInput
    ) {
      status
    }
  }
`
