import { gql } from 'graphql-request'

export const COINS = gql`
  query Coins(
    $where: FindAllCoinWhere
    $type: CoinType
    $orderBy: FindAllCoinOrderBy
  ) {
    coins(where: $where, type: $type, orderBy: $orderBy) {
      data {
        id
        name
        imgPath
      }
    }
  }
`
