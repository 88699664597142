import { Expose, Type } from 'class-transformer'
import { ConsentType } from '@models/consent/ConsentType'

export class WritersConsentType {
  @Type(() => ConsentType)
  consent: ConsentType

  @Expose()
  createdAt: string
}
