import React from 'react'
import { ModalProps } from './interface'

const withModal = (Component: React.FC<ModalProps>) => {
  function HOC({ isOpen, ...props }: ModalProps) {
    if (!isOpen) {
      return null
    }

    const componentProps = {
      isOpen,
      ...props,
    }
    return <Component {...componentProps} />
  }
  return HOC
}
export { withModal }
