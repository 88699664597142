import { gql } from 'graphql-request'

export const SEARCH_USER = gql`
  query SearchUser($searchText: String, $limitPerPage: Int, $page: Int) {
    searchUser(
      searchText: $searchText
      limitPerPage: $limitPerPage
      page: $page
    ) {
      data {
        id
        displayName
        profilePageSlug
        avatarImgPath
      }
      total
    }
  }
`
