import { Expose, Type } from 'class-transformer'
import { PromotionCodeTypeEnum } from '@interfaces/PromotionCodeTypeEnum'
import { CoinType, PromotionType } from '@models/giftCode/CheckRedeemCodeType'
import { BuyCoinConditionEnum } from '@interfaces/BuyCoinConditionEnum'

export class CheckPromotionCodeType {
  @Expose()
  @Type(() => PromotionType)
  promotion: PromotionType

  @Expose()
  @Type(() => CoinType)
  coin: CoinType

  @Expose() benefitValue: number

  @Expose() type: PromotionCodeTypeEnum

  @Expose() buyCoinConditionValue: number

  @Expose() buyCoinCondition: BuyCoinConditionEnum
}
