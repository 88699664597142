import { gql } from 'graphql-request'

export const GET_COIN_LOG_LIST = gql`
  query UserCoinLogs(
    $limitPerPage: Int
    $page: Int
    $orderBy: UserCoinLogsOrderBy
    $type: CoinLogTypeEnum
    $startDate: DateTime
    $endDate: DateTime
    $filter: CoinsLogFilterEnum
  ) {
    userCoinLogs(
      limitPerPage: $limitPerPage
      page: $page
      orderBy: $orderBy
      type: $type
      startDate: $startDate
      endDate: $endDate
      filter: $filter
    ) {
      data {
        id
        paidCoin
        freeCoin
        type
        activity
        reference {
          id
          name
          characterDescription
          coupon
          promotionName
        }
        transaction {
          amount
          platform
          paymentMethod
          transactionId
        }
        createdAt
        coinLogs {
          coin {
            imgPath
          }
        }
        coin {
          imgPath
        }
      }
      total
      page
    }
  }
`
