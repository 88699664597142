import { gql } from 'graphql-request'

export const TRANSACTION = gql`
  query Transaction($transactionId: String, $chargeId: String) {
    transaction(transactionId: $transactionId, chargeId: $chargeId) {
      id
      status
      amount
      failureMessage
    }
  }
`
