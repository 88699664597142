import { QueryClient } from 'react-query'

import { Client } from '@client/Client'
import { GraphQLClient } from '@client/GraphQLClient'
import { API_URL } from '@configs/config'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const gqlApiInstance = new GraphQLClient(API_URL)
const client = new Client(gqlApiInstance)

export { client, queryClient, gqlApiInstance }
