import { gql } from 'graphql-request'

export const GET_RECOMMENDATION_BOOKS = gql`
  query GetRecommendationBooks(
    $category: Int
    $bookId: Int
    $bookType: BookType
    $isEbook: Boolean
    $limitPerPage: Int
  ) {
    getRecommendationBooks(
      category: $category
      bookId: $bookId
      bookType: $bookType
      isEbook: $isEbook
      limitPerPage: $limitPerPage
    ) {
      id
      writer
      title
      cover
      tags {
        id
        name
      }
      category {
        id
        name
      }
      penName {
        firstPenName
        user {
          id
          avatarImgPath
          displayName
          profilePageSlug
          writer {
            affiliation
            status
          }
        }
      }
      coverImgPath
      coverResizeImgPath
      isLiked
      likeCount
      viewCount
      commentCount
      publishedChapterCount
      isEnded
      bookType
      publishedAt
      writingType
      isTranslated
      isEpub
      isPdf
      rating {
        id
        name
      }
    }
  }
`
