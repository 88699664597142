import { gql } from 'graphql-request'

export const CREATE_INTERNET_BANKING_TRANSACTION = gql`
  mutation CreateInternetBankingTransaction(
    $createInternetBankingInput: CreateInternetBankingInput!
  ) {
    createInternetBankingTransaction(
      createInternetBankingInput: $createInternetBankingInput
    ) {
      transferAmount
      expiredAt
    }
  }
`
