import React, {
  ReactPortal,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useQueryClient } from 'react-query'
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  query,
} from '@firebase/firestore'

import { Button } from '@components/Buttons'
import { useAuthentication } from '@hooks/useAuthentication'
import { addComma } from '@lib/utils'
import { PromotionSalePopUpType } from './interface'

export function PromotionSaleSnapshot() {
  const { user } = useAuthentication()
  const unsubscribe = useRef<any>(null)
  const queryClient = useQueryClient()
  const [component, setComponent] = useState<ReactPortal | null>(null)
  const [popUps, setPopUps] = useState<PromotionSalePopUpType[]>([])
  const db = getFirestore()
  const queryPromotionSale = query(
    collection(db, 'promotion_sale', `${user.id}`, 'promotion_sales')
  )

  const deletePromotionSale = useCallback(
    async (id: string) => {
      await deleteDoc(
        doc(db, 'promotion_sale', `${user.id}`, 'promotion_sales', id)
      )
      setPopUps(prev => prev.filter(row => row.id !== id))
    },
    [db, user.id]
  )

  useEffect(() => {
    unsubscribe.current = onSnapshot(queryPromotionSale, snapshot => {
      const docs = snapshot
        .docChanges()
        .filter(row => row.type === 'added')
        .map(row => ({
          benefitCoin: row.doc.data().benefitCoin,
          benefitValue: row.doc.data().benefitValue,
          id: row.doc.id,
        }))

      if (docs.length) {
        queryClient.invalidateQueries('userCoin')
        setPopUps(prev => [...prev, ...docs])
      }
    })

    return () => {
      unsubscribe.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id])

  useEffect(() => {
    if (popUps.length) {
      const [popUp] = popUps
      setComponent(
        createPortal(
          <div className='fixed right-0 left-0 top-0 bottom-0 bg-secondary-2/30 flex justify-center items-center z-[100]'>
            <div className='py-[40px] w-[400px] px-[30px] mobile:px-[20px] flex flex-col items-center mobile:w-[348px] bg-white rounded-[10px]'>
              <div className='mb-[10px] font-mitr text-2xl font-medium text-center'>
                ยินดีด้วย
                <br />
                คุณได้รับเหรียญฟรี
              </div>
              <div className='mb-[20px] mobile:mb-[10px]'>
                <img
                  src='/promotion-sale-logo.webp'
                  alt='promotion-sale-logo'
                  width={150}
                />
              </div>
              <p className='text-center font-light text-sm mb-[30px]'>
                ได้รับเหรียญ{popUp.benefitCoin} {addComma(popUp.benefitValue)}{' '}
                เหรียญ
              </p>
              <Button
                type='button'
                className='bg-primary text-white w-[102px] text-[14px] mobile:w-full'
                onClick={() => deletePromotionSale(popUp.id)}
              >
                ตกลง
              </Button>
            </div>
          </div>,
          document.body
        )
      )
    } else {
      setComponent(null)
    }
  }, [deletePromotionSale, popUps])

  return component
}
