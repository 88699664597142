import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { pageView } from '@lib/facebookPixel'

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}

export function useFacebookPixel() {
  const router = useRouter()

  function handleRouteChange() {
    pageView()
  }

  useEffect(() => {
    pageView()

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
