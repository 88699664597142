import { useMutation } from 'react-query'

import { useConsent } from '@hooks/useConsent'
import { useAuthentication } from '@hooks/useAuthentication'
import { WriterTermsOfServiceModal } from '@features/myWriting/pages/MyWritingPage/components/UpgradeToWriter/components/WriterTermsOfServiceModal'
import { useConsentAction } from '@hooks/consent/useConsentAction'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'

export function InvalidWriterConsent({
  updateInvalidConsent,
}: {
  updateInvalidConsent: (value?: ConsentTypeEnum) => void
}) {
  const consentClient = useConsentAction()
  const { refetchMyProfile, refetchUserCoin } = useAuthentication()
  const { data } = useConsent()

  const { mutate: updateWriterConsent, isLoading } = useMutation(
    (id: number) => consentClient.updateWriterConsent(id),
    {
      onSuccess: async () => {
        updateInvalidConsent(undefined)
        await refetchMyProfile()
        await refetchUserCoin()
      },
      onError: async error => {
        if (
          error instanceof Error &&
          error.message.includes('Writer already make this consent')
        ) {
          updateInvalidConsent(undefined)
          await refetchMyProfile()
          await refetchUserCoin()
        }
      },
    }
  )

  if (!data) return null

  return (
    <WriterTermsOfServiceModal
      title={data.name}
      content={data.content}
      submittedDate=''
      showApproveAction
      hideCloseButton
      onApprove={() => {
        if (!isLoading) {
          updateWriterConsent(data.id)
        }
      }}
    />
  )
}
