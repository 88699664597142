import { gql } from 'graphql-request'

export const GET_PROFILE_BY_ID = gql`
  query GetProfileById($id: Int!) {
    getProfileById(id: $id) {
      id
      avatarImgPath
      bannerImgPath
      fullName
      email
      username
      displayName
      facebookLink
      twitterLink
      totalFollowing
      totalFollower
      totalBook
      totalBookshelf
      totalRead
      aboutMe
      isFollowed
    }
  }
`
