import { DateTime } from 'luxon'
import { PromotionChallengeActionEnum } from '@interfaces/PromotionChallengeActionEnum'

export const promotionChallengeActionText = (text?: string) => {
  switch (text) {
    case PromotionChallengeActionEnum.LOVE:
      return '“กดหัวใจ”'
    case PromotionChallengeActionEnum.COMMENT:
      return '“แสดงความคิดเห็น”'
    case PromotionChallengeActionEnum.SHARE:
      return '“การแชร์”'
    case PromotionChallengeActionEnum.FINISH_PROFILE:
      return '“กรอกข้อมูลโปรไฟล์ครบ”'
    case PromotionChallengeActionEnum.READ:
      return '“อ่านเรื่อง”'
    case PromotionChallengeActionEnum.UNLOCK_CHAPTER:
      return '“ปลดล็อกนิยาย”'
    case PromotionChallengeActionEnum.DONATE:
      return '“สนับสนุนตัวละคร”'
    case PromotionChallengeActionEnum.SET_PRICE_CHAPTER:
      return '“เพิ่มตอนติดเหรียญ”'
    case PromotionChallengeActionEnum.CREATE_BOOK:
      return '“สร้างเรื่องใหม่”'
    default:
      return '-'
  }
}

export const promotionDate = (
  start: string,
  end: string,
  prefix = 'ตั้งแต่'
) => {
  const localeDate = (date: string) => {
    const newDate = new Date(date)
    return `${newDate.toLocaleDateString('th-TH', {
      day: 'numeric',
      month: 'short',
    })} ${newDate.getFullYear()}`
  }

  const startDate =
    DateTime.now().endOf('day').toUTC().valueOf() >
      DateTime.fromISO(start).toUTC().valueOf() &&
    DateTime.now().endOf('day').toUTC().valueOf() <
      DateTime.fromISO(end).toUTC().valueOf()
      ? 'วันนี้'
      : `${prefix} ${localeDate(start)}`

  return `${startDate} - ${localeDate(end)}`
}
