import { gql } from 'graphql-request'

export const CREATE_PAYMENT_SHOPEE_PAY = gql`
  mutation CreatePaymentShopeePay(
    $createShopeePayInput: CreateShopeePayInput!
  ) {
    createPaymentShopeePay(createShopeePayInput: $createShopeePayInput) {
      status
      redirectUrl
    }
  }
`
