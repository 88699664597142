/* eslint-disable max-classes-per-file */
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { WritingEnum } from '@interfaces/WritingEnum'
import { BookRatingType } from '@models/book/BookRatingType'
import { TagType } from '@models/story/TagType'
import { Expose, Transform, Type } from 'class-transformer'
import { CategoryType } from './CategoryType'

class WriterType {
  @Expose()
  affiliation: AffiliationEnum

  @Expose()
  status: WriterStatusEnum
}

class UserType {
  @Expose() id: number

  @Expose() displayName: string

  @Expose() avatarImgPath: string

  @Expose()
  @Type(() => WriterType)
  @Transform(({ value }) => value || {})
  writer: WriterType

  @Expose()
  profilePageSlug: string
}

class PenNameType {
  @Expose() firstPenName: string

  @Expose()
  @Type(() => UserType)
  user: UserType
}

export class RecommendCatagoryType {
  @Expose()
  id: number

  @Expose()
  writer: string

  @Expose() coverImgPath: string

  @Expose() coverResizeImgPath: string

  @Expose()
  title: string

  @Expose()
  cover: string

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose()
  @Type(() => TagType)
  tags: TagType[]

  @Expose()
  category: CategoryType

  @Expose()
  viewCount: number

  @Expose()
  likeCount: number

  @Expose()
  commentCount: number

  @Expose({ name: 'publishedChapterCount' })
  @Transform(({ value }) => value ?? 0)
  chapterCount: number

  @Expose()
  isEnded: boolean

  @Expose()
  bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value ?? false)
  isLiked: boolean

  @Expose()
  publishedAt: string

  @Expose()
  writingType: WritingEnum

  @Expose()
  @Type(() => BookRatingType)
  rating: BookRatingType

  @Expose() isTranslated: boolean

  @Expose() isEpub: boolean

  @Expose() isPdf: boolean
}
