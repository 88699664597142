import { Expose, Type } from 'class-transformer'
import { FreeCoinType } from './FreeCoinType'

export class UserFreeCoinResponse {
  @Expose()
  @Type(() => FreeCoinType)
  data: FreeCoinType[]

  @Expose() total: number

  @Expose() page: number
}
