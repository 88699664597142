import { ReactPortal, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { LimitAuthenticationModal } from '@features/authentication/components/LimitAuthenticationModal'
import { useAuthentication } from '@hooks/useAuthentication'

export function TriggleLimitAuthen() {
  const { showLimitAuthentication } = useAuthentication()
  const [component, setComponent] = useState<ReactPortal | null>(null)

  useEffect(() => {
    document.body.style.overflow = showLimitAuthentication ? 'hidden' : ''

    setComponent(
      showLimitAuthentication
        ? createPortal(<LimitAuthenticationModal />, document.body)
        : null
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLimitAuthentication])

  return component
}
