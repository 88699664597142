export enum ActionEnum {
  CONVERSION = 'conversion',
}

export function event(
  action: ActionEnum,
  params: {
    send_to: string
    value?: number
    currency?: string
    transaction_id?: string
  }
) {
  window.gtag('event', action, params)
}
