import dynamic from 'next/dynamic'

import { CategoryMenuDrawerAcceptProps } from '@features/home/pages/HomePage/components/CategoryMenuDrawer/interface'
import { WithChatDrawerProps } from '@components/layouts/components/Chat/components/ChatDrawer/interface'
import { StoryReaderProviderType } from '@hooks/book/useStoryReader'
import { StoryProps } from './interface'

const StoryReaderProvider = dynamic<StoryReaderProviderType>(() =>
  import('@hooks/book/useStoryReader').then(v => v.StoryReaderProvider)
)
const StoryDrawer = dynamic<unknown>(() =>
  import('@components/StoryDrawer').then(v => v.StoryDrawer)
)
const ChatDrawer = dynamic<WithChatDrawerProps>(() =>
  import('@components/layouts/components/Chat/components/ChatDrawer').then(
    v => v.ChatDrawer
  )
)
const CategoryMenuDrawer = dynamic<CategoryMenuDrawerAcceptProps>(() =>
  import('@features/home/pages/HomePage/components/CategoryMenuDrawer').then(
    v => v.CategoryMenuDrawer
  )
)

export const DrawerList = {
  story: ({ bookId, initialData }: StoryProps) => (
    <StoryReaderProvider bookId={bookId} initialData={initialData}>
      <StoryDrawer />
    </StoryReaderProvider>
  ),
  categoryMenu: (props: CategoryMenuDrawerAcceptProps) => (
    <CategoryMenuDrawer {...props} />
  ),
  chatDrawer: (props: any) => <ChatDrawer {...props} />,
}
