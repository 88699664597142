import { gql } from 'graphql-request'

export const CREATE_OR_UPDATE_PAYMENT_TRUE_MONEY = gql`
  mutation CreateOrUpdatePaymentTrueMoney(
    $createOrUpdatePaymentTrueMoneyInput: CreateOrUpdatePaymentTrueMoneyInput!
  ) {
    createOrUpdatePaymentTrueMoney(
      createOrUpdatePaymentTrueMoneyInput: $createOrUpdatePaymentTrueMoneyInput
    ) {
      merchantName
      amount
      transactionCreatedAt
      transactionId
      otpReference
      account
      authorizationCode
      coupon
      linkAgreement
    }
  }
`
