import { useQuery } from 'react-query'

import { useAuthentication } from './useAuthentication'
import { useConsentAction } from './consent/useConsentAction'

export function useConsent() {
  const consentClient = useConsentAction()
  const { canFetchApi } = useAuthentication()
  const { data } = useQuery(
    'writer-consent',
    () => consentClient.getWriterConsent(),
    {
      enabled: canFetchApi,
    }
  )

  return {
    data,
  }
}
