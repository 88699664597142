import { useEffect } from 'react'
import { doc, getFirestore, onSnapshot } from '@firebase/firestore'
import Router from 'next/router'

import { useCategories } from '@hooks/useCategories'

interface Maintenance {
  webStatus: boolean
}

export function TriggerMaintenance() {
  const db = getFirestore()
  const maintenanceDoc = doc(db, 'maintenance', 'maintenanceDocument')
  const { categories } = useCategories()

  useEffect(() => {
    const unsubscribe = onSnapshot(maintenanceDoc, document => {
      const data = document.data()
      if (
        (data as Maintenance).webStatus &&
        Router.pathname !== '/maintenance'
      ) {
        Router.replace('/maintenance')
      } else if (Router.pathname === '/maintenance' && categories?.length) {
        Router.replace('/')
      }
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  return null
}
