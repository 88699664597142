import { Expose, Type } from 'class-transformer'
import { WriterEnum } from '@interfaces/WriterEnum'
import { AffiliationEnum } from '@interfaces/AffiliationEnum'
import { ResidenceEnum } from '@interfaces/ResidenceEnum'
import { CertificationTaxEnum } from '@interfaces/CertificationTaxEnum'
import { WriterStatusEnum } from '@interfaces/WriterStatusEnum'
import { BankAccountType } from '@models/writer/BankAccountType'
import { WritersConsentType } from './WritersConsentType'

export class WriterType {
  @Expose()
  id: number

  @Expose() bankAccount: BankAccountType

  @Expose()
  type: WriterEnum

  @Expose()
  affiliation: AffiliationEnum

  @Expose()
  fullName: string

  @Expose()
  idNumber: string

  @Expose()
  email: string

  @Expose()
  phoneNumber: string

  @Expose()
  residence: ResidenceEnum

  @Expose()
  country: string

  @Expose()
  address: string

  @Expose()
  postcode: string

  @Expose()
  province: string

  @Expose()
  district: string

  @Expose()
  subDistrict: string

  @Expose()
  idCardImgPath: string

  @Expose()
  isSameMainAddress: boolean

  @Expose()
  sendResidence: ResidenceEnum

  @Expose()
  sendCountry: string

  @Expose()
  sendAddress: string

  @Expose()
  sendPostcode: string

  @Expose()
  sendProvince: string

  @Expose()
  sendDistrict: string

  @Expose()
  sendSubDistrict: string

  @Expose()
  certificationTax: CertificationTaxEnum

  @Expose()
  companyName: string

  @Expose()
  taxId: string

  @Expose()
  companyCertificateImgPath: string

  @Expose()
  vatRegistrationCertificateImgPath: string

  @Expose()
  coordinatorFullName: string

  @Expose()
  coordinatorEmail: string

  @Expose()
  coordinatorPhoneNumber: string

  @Expose()
  accountingFullName: string

  @Expose()
  accountingEmail: string

  @Expose()
  accountingPhoneNumber: string

  @Expose()
  certificateCountry: string

  @Expose()
  certificateAddress: string

  @Expose()
  certificatePostcode: string

  @Expose()
  certificateProvince: string

  @Expose()
  certificateDistrict: string

  @Expose()
  certificateSubDistrict: string

  @Type(() => WritersConsentType)
  writersConsents: WritersConsentType[]

  @Expose()
  status: WriterStatusEnum

  @Expose()
  rejectedReason: string
}
