import { plainToInstance } from 'class-transformer'

import { REMOVE_DEVICE } from '@client/collections/Device/schemas/removeDevice'
import { SIGN_IN_DEVICE } from '@client/collections/Device/schemas/signInDevice'
import { SignInDeviceResponse } from '@models/device/SignInDeviceResponse'
import { gqlApiInstance } from '@client/init'
import { UserFirstLoginProviderEnum } from '@interfaces/UserFirstLoginProviderEnum'
import { SET_DEVICE_TOKEN } from '@client/collections/Device/schemas/setDeviceToken'

export function useDeviceAction() {
  async function signInDevice({
    provider,
    deviceToken,
  }: {
    provider?: UserFirstLoginProviderEnum
    deviceToken?: string
  }): Promise<SignInDeviceResponse> {
    const res = await gqlApiInstance.request(SIGN_IN_DEVICE, {
      signInDeviceInput: { deviceToken, provider },
    })

    return plainToInstance(SignInDeviceResponse, res.signInDevice)
  }

  async function removeDevice(token: string, id: number): Promise<void> {
    await gqlApiInstance.request(REMOVE_DEVICE, {
      removeDeviceInput: {
        id,
      },
    })
  }

  async function setDeviceToken(token: string) {
    await gqlApiInstance.request(SET_DEVICE_TOKEN, {
      setDeviceTokenInput: {
        deviceToken: token,
      },
    })
  }

  return { signInDevice, removeDevice, setDeviceToken }
}
