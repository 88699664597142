import { FirstLoginProviderEnum } from '@interfaces/FirstLoginProviderEnum'
import { GenderEnum } from '@interfaces/GenderEnum'
import { UserPunishmentType } from '@models/user/UserPunishmentType'
import { WriterRequestType } from '@models/writer/WriterRequestType'
import { WriterType } from '@models/writer/WriterType'

export const defaultUser = {
  id: 0,
  avatarImgPath: '',
  bannerImgPath: '',
  idNumber: '',
  fullName: '',
  email: '',
  phoneNumber: '',
  birthDay: new Date(),
  gender: GenderEnum.OTHER,
  isAcceptPolicy: false,
  username: '',
  displayName: '',
  facebookLink: '',
  twitterLink: '',
  profilePageSlug: '',
  aboutMe: '',
  allowReadLikeHistory: false,
  allowSendMessage: false,
  allowShowCoinUsage: false,
  totalFollowing: 0,
  totalFollower: 0,
  totalBook: 0,
  totalBookshelf: 0,
  totalRead: 0,
  ageVerify: null,
  idCardImgPath: '',
  newBookNotification: false,
  newChapterNotification: false,
  contentUpdatedNotification: false,
  commentRepliedNotification: false,
  bookEndedNotification: false,
  pushNotification: false,
  mareadsEmailNotification: false,
  coinPurchasedEmailNotification: false,
  coinUsedEmailNotification: false,
  addresses: [],
  writer: {} as WriterType,
  writerRequest: {} as WriterRequestType,
  subscribedWriterNotification: false,
  commentedWriterNotification: false,
  likedWriterNotification: false,
  taggedWriterNotification: false,
  supportedWriterNotification: false,
  pushWriterNotification: false,
  mareadsEmailWriterNotification: false,
  salesSummaryWriterNotification: false,
  writingDisabled: false,
  isBan: false,
  ageVerifyRejectedReason: '',
  isAdmin: false,
  firstLoginProvider: FirstLoginProviderEnum.EMAIL,
  usernameEditable: false,
  emailEditable: false,
  punishment: {} as UserPunishmentType,
  homeBannerEnable: false,
  coinBannerEnable: false,
  isPermanentBan: false,
}
