import { gql } from 'graphql-request'

export const CREATE_PAYMENT_QR = gql`
  mutation CreatePaymentQR($createPaymentQrInput: CreatePaymentQRInput!) {
    createPaymentQR(createPaymentQRInput: $createPaymentQrInput) {
      status
      orderId
    }
  }
`
