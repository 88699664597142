import { gql } from 'graphql-request'

export const CATEGORY = gql`
  query Category($categoryId: Int!) {
    category(id: $categoryId) {
      id
      name
      seoManga
      seoNovel
      seoTitleNovel
      seoDescriptionNovel
      seoTitleManga
      seoDescriptionManga
    }
  }
`
