import { FC, SVGProps } from 'react'

import { IconProps } from './interface'

export function Icon(
  SvgComponent: FC<SVGProps<SVGSVGElement>>
): React.FC<IconProps> {
  return ({ height = '24', width = '24', className = '' }: IconProps) => {
    const svgProps = {
      height,
      width,
      className,
    }
    return <SvgComponent {...svgProps} />
  }
}
