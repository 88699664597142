import { gql } from 'graphql-request'

export const GET_PROFILE = gql`
  query GetProfile {
    getProfile {
      id
      avatarImgPath
      bannerImgPath
      idNumber
      fullName
      email
      phoneNumber
      birthDay
      gender
      isAcceptPolicy
      username
      displayName
      facebookLink
      twitterLink
      profilePageSlug
      aboutMe
      allowReadLikeHistory
      allowSendMessage
      allowShowCoinUsage
      totalFollowing
      totalFollower
      totalBook
      totalBookshelf
      totalRead
      ageVerify
      ageVerifyRejectedReason
      idCardImgPath
      newBookNotification
      newChapterNotification
      contentUpdatedNotification
      commentRepliedNotification
      bookEndedNotification
      pushNotification
      mareadsEmailNotification
      coinPurchasedEmailNotification
      coinUsedEmailNotification
      writingDisabled
      isBan
      isAdmin
      addresses {
        id
        fullName
        address
        postcode
        province
        district
        subDistrict
        phoneNumber
        email
        isDefault
      }
      writerRequest {
        id
        bankAccount {
          bankName
          bankBranch
          bankProvince
          accountNumber
          accountName
          accountType
          bankCoverImgPath
          bankCode
          bankBranchCode
        }
        writer {
          id
          affiliation
          writersConsents {
            consent {
              id
              name
              content
            }
            createdAt
          }
        }
        type
        affiliation
        fullName
        idNumber
        email
        phoneNumber
        residence
        country
        address
        postcode
        province
        district
        subDistrict
        idCardImgPath
        isSameMainAddress
        sendResidence
        sendCountry
        sendAddress
        sendPostcode
        sendProvince
        sendDistrict
        sendSubDistrict
        certificationTax
        companyName
        taxId
        companyCertificateImgPath
        vatRegistrationCertificateImgPath
        coordinatorFullName
        coordinatorEmail
        coordinatorPhoneNumber
        accountingFullName
        accountingEmail
        accountingPhoneNumber
        certificateCountry
        certificateAddress
        certificatePostcode
        certificateProvince
        certificateDistrict
        certificateSubDistrict
        writersConsents {
          consent {
            id
            name
            content
          }
          createdAt
        }
        status
        rejectedReason
      }
      subscribedWriterNotification
      commentedWriterNotification
      likedWriterNotification
      taggedWriterNotification
      supportedWriterNotification
      pushWriterNotification
      mareadsEmailWriterNotification
      salesSummaryWriterNotification
      firstLoginProvider
      usernameEditable
      emailEditable
      webHomeBannerEnable
      webCoinBannerEnable
    }
  }
`
