export enum PunishmentPeriodEnum {
  TWELVE_HOURS = 'TWELVE_HOURS',
  THREE_DAYS = 'THREE_DAYS',
  SEVEN_DAYS = 'SEVEN_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  FOREVER = 'FOREVER',
}

export const punishmentPeriodValue = {
  [PunishmentPeriodEnum.TWELVE_HOURS]: '12 ชม.',
  [PunishmentPeriodEnum.THREE_DAYS]: '3 วัน',
  [PunishmentPeriodEnum.SEVEN_DAYS]: '7 วัน',
  [PunishmentPeriodEnum.THIRTY_DAYS]: '30 วัน',
  [PunishmentPeriodEnum.FOREVER]: 'ถาวร',
}
